import React from "react";
import { Button as ButtonBootstrap } from "react-bootstrap";

export function Button({ children, disabled, ...props }) {
  return (
    <ButtonBootstrap   disabled={disabled} {...props}>
      {disabled ?(
        <>
        <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
        <span role="status" className="ps-1">Carregando...</span>
        </>
      ): children}
    </ButtonBootstrap>
  );
}
