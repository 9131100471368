import { gql } from "@apollo/client";

export const SaqueQueries = {
  getSolicitacoesSaqueCliente: gql`
    query GetSolicitacoesSaque($paginacao: PaginacaoInput) {
      getSolicitacoesSaqueCliente(paginacao: $paginacao) {
        total
        saques {
          _id
          valor
          cod
          status
          createdAt
          pix {
            nome
            chave_pix
          }
          usuario {
            saldo
          }
        }
      }
    }
  `,
  getSaques: gql`
    query GetSaque($paginacao: PaginacaoInput) {
      getSaques(paginacao: $paginacao) {
        total
        saques {
          _id
          transaction_id
          external_reference
          nome_favorecido
          cpf_favorecido
          valor
          tipo_chave
          chave_pix
          status
          createdAt
          updatedAt
        }
      }
    }
  `,
};
