import resolverRewardFut from '../../../utils/resolver-reward-fut';
import resolverTexts from '../../../utils/resolver-texts';

import { FaDog } from 'react-icons/fa';
import { handleText } from '../../../utils/handle-text';
import { dataMovimento } from '../modules/dataMovimento';

export const CardProductJB = ({ onClick, ...product }) => {
  return (
    <div className="position-relative pointer my-2  ">
      <div onClick={onClick} className="card card-body    ">
      <div className="d-flex d-flex  gap-2 align-items-center justify-content-start">
          <div className=" d-flex d-flex  gap-2 align-items-center justify-content-start ">
          <strong>{product?.cod}</strong>
            {resolverTexts(product.status)}
            {resolverRewardFut(
              product.status,
              product.status_premio_futebol,
              product.valor_premios
            )}
          </div>
        </div>
        <div className="row mt-2">

          <div className="col-6 col-md-4  d-flex flex-column text-center ">
            <small>Jogos</small>
            <strong>{product.jogos.length}</strong>
          </div>

          <div className="col-6 col-md-4 d-flex flex-column text-center ">
            <small>Forma de pagamento</small>
            <strong>{product.forma_pagamento}</strong>
          </div>

          <div className="col-6 col-md-4 d-flex flex-column text-center ">
            <small>Extração</small>
            <strong> {product.extracao.descricao}</strong>
          </div>

          <div className="col-6 col-md-4 d-flex flex-column text-center ">
            <small>Valor total</small>
            <strong>{handleText.asMoney(product.valor_bruto)}</strong>
          </div>

          <div className="col-6 col-md-4 d-flex flex-column text-center ">
            <small>Data</small>
            <strong>{dataMovimento(product.data_movimento, product.futebol)}</strong>
          </div>
        

     

        </div>
      </div>

      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">
        <FaDog size={15} />
      </span>
    </div>
  );
};
