import { gql } from "@apollo/client";

export const FutMutations = {
  criarConta: gql`
    mutation Futebol($data: String) {
      futebolsite_criarConta(data: $data) {
        _id
        token
        nome
        cpf
        telefone
        email
        status
      }
    }
  `,
  recuperarSenha: gql`
    mutation Futebol($cpf: String) {
      futebolsite_recuperarSenha(cpf: $cpf)
    }
  `,
  login: gql`
    mutation Futebol($data: String) {
      futebolsite_login(data: $data) {
        _id
        token
        nome
        cpf
        telefone
        email
        status
        saldo
      }
    }
  `,
  apostar: gql`
    mutation Mutation($dados: MovimentoFutebolSiteInput) {
      movimentoFutebolSite(dados: $dados) {
        _id
        cod
        pix {
          qrcode
          image_qrcode
          solicitacaopagador
          calendario {
            criacao
          }
        }
      }
    }
  `,
};
