import React, { useEffect, useState } from "react";
import { gameRequests } from "../../requests/gameRequests";

export default function RegulamentoJB() {
  const [regulamento, setRegulamento] = useState("");

  useEffect(() => {
    getRegulamentoJB();
  }, []);

  async function getRegulamentoJB() {
    try {
      let data = await gameRequests.getRegulamentoJB();
      setRegulamento(data);
    } catch (error) { }
  }
  return (
    <div className="px-3">
      <h1>Regulamento</h1>
      <div  className='text-uppercase' style={{marginTop:'.5rem', fontSize:'.75rem', whiteSpace:'break-spaces'}}>{regulamento}</div>
    </div>
  );
}
