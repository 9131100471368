import dayjs from "dayjs";
import 'dayjs/locale/pt-br';
import { useFormik } from "formik";
import { useState } from "react";
import { Form } from "react-bootstrap";
import * as Yup from 'yup';
import { Button } from "../../components/buttons/button";
import { userRequests } from "../../requests/gameRequests";
dayjs.locale('pt-br');

export function AlterarSenha() {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      "nova_senha": "",
      "cnova_senha": ""
    },
    validationSchema: Yup.object().shape({
      nova_senha: Yup.string().required("Campo obrigatório"),
      cnova_senha: Yup.string().required("Campo obrigatório"),
    }),
    onSubmit: submit
  })



  async function submit(values) {
    if (loading) return;
    setLoading(true);
    try {
      await userRequests.alterarSenha(values.nova_senha, values.cnova_senha);
      formik.resetForm();
      alert("Senha alterada com sucesso!");
    } catch (error) {
      let err = 'Ocorreu um problema ao solicitar o saque';
      if (typeof error == 'string') err = error;
    }
    setLoading(false);
  }

  return (
    <div className="container py-3">
      <h3>Alterar senha</h3>
      <hr />
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <Form.Group>
              <Form.Label htmlFor="novasenha-input">Nova senha *</Form.Label>
              <Form.Control id={'novasenha-input'} placeholder="Nova senha" value={formik.values.nova_senha} onChange={formik.handleChange('nova_senha')} />
              {
                formik.errors?.nova_senha && formik?.touched?.nova_senha && (
                  <small style={{ color: "red" }}>{formik.errors?.nova_senha}</small>
                )
              }
            </Form.Group>
          </div>
          <div className="col-md-12">
            <Form.Group>
              <Form.Label htmlFor="cnovasenha-input">Confirme a nova senha *</Form.Label>
              <Form.Control id={'cnovasenha-input'} placeholder="Confirme a nova senha" value={formik.values.cnova_senha} onChange={formik.handleChange('cnova_senha')} />
              {
                formik.errors?.cnova_senha && formik?.touched?.cnova_senha && (
                  <small style={{ color: "red" }}>{formik.errors?.cnova_senha}</small>
                )
              }
            </Form.Group>
          </div>
        </div>
        <hr />
        <div className="d-flex flex-row gap-2">
          <Button type="submit" disabled={loading}>Alterar</Button>
        </div>
      </Form>

    </div>
  );
}