import React, { useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useUsuarioContext } from '../../../../context/context-user';
import { handleIcons } from '../../../../utils/handle-icon';
import handleRouter from '../../../../utils/handle-router';
import { Button } from '../../../buttons/button';
import { getMenuOptions } from './sidebar';
export default function Header() {
  const { usuario, limparSessao, limparReference, setMakeLogin, _reference } =
    useUsuarioContext();
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  return (
    <Navbar
      expand="md"
      expanded={expanded}
      onToggle={() => setExpanded(!expanded)}
      collapseOnSelect
      className="sticky-top  z-1  d-md-none  bg-primary-main text-light py-2 shadow  "
    >
      <Container>
        <Navbar.Brand className="text-light" href="/">
          TJBET.BET
        </Navbar.Brand>
        <Navbar.Toggle className="bg-light" aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto gap-2 py-2">
            {getMenuOptions(location).map(({ name, link, Icon }) => {
              if (name != 'Início') {
                return (
                  <Nav.Link
                    as={Link}
                    eventKey={link}
                    className="text-light d-flex flex-row gap-2  btn btn-primary align-items-center  ps-2"
                    key={name}
                    to={link}
                  >
                    <Icon />
                    {name}
                  </Nav.Link>
                );
              } else {
                return (
                  <Nav.Link
                  onClick={() => {
                    navigator(0)
                  }}
                  as={Link}
                  eventKey={link}
                  className={`text-light  d-flex flex-row gap-2  btn btn-primary align-items-center  ps-2 ${location.pathname == link ? 'active':''}`}
                  key={name}
                  to={link}
                  >
                    <Icon />
                    {name}
                  </Nav.Link>
                );
              }
            })}
            <hr />
            {usuario ? (
              <>
                <div className="d-flex flex-column gap-2">
                  <Nav.Link
                    as={Link}
                    eventKey={'minha-conta'}
                    className="text-light d-flex flex-row gap-2  btn btn-primary align-items-center  ps-2"
                    to={handleRouter.conta}
                  >
                    <handleIcons.User />
                    Minha conta
                  </Nav.Link>

                  <Button
                    variant="danger"
                    onClick={() => {
                      setExpanded(false);
                      limparSessao();
                    }}
                  >
                    Sair
                  </Button>
                </div>
              </>
            ) : (
              <div className="d-flex flex-column">
                <Button
                  variant="primary"
                  onClick={() => {
                    setExpanded(false);
                    setMakeLogin(true);
                  }}
                >
                  Login
                </Button>
              </div>
            )}
            {_reference?._id && (
              <div className="d-flex flex-column mt-2">
                <Button
                  variant="info"
                  onClick={() => {
                    setExpanded(false);
                    if (
                      window.confirm(
                        'Deseja remover a referência de indicação?'
                      )
                    ) {
                      limparReference();
                    }
                  }}
                >
                  <small>Referência</small>
                  <br /> {_reference?.cod ? `${_reference?.cod}. ` : ''}
                  {_reference?.nome}
                </Button>
              </div>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
