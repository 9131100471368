import React from 'react';
import { useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import { ContextUsuario } from '../../../../context/context-user';
import { Shadow } from '../../../elements/shadow';

const width = {
  wide: '30vw',
  mobile: '90vw',
};

const animation = right => keyframes`
  30% {
    right: 0;
  }
  80% {
    right: 0;
  }
  to{
    right: ${right};
    box-shadow: none;
  }
`;

const Component = {
  Box: styled.div`
    top: 2rem;
    right: -${width.wide};
    max-width: ${width.wide};
    background-color: ${({ theme, variant = 'primary' }) =>
      theme.palette[variant].main};
    color: ${({ theme, variant = 'primary' }) => theme.palette[variant].text};
    padding: ${({ theme }) => theme.space_size.md};
    border-radius: ${({ theme }) =>
      `${theme.space_size.sm} 0px 0px ${theme.space_size.sm}`};
    ${Shadow('primary')};
    animation: ${animation(`-${width.wide}`)} 5s forwards;
    &:hover {
      animation-play-state: paused;
    }
    @media (max-width: 800px) {
      right: -${width.mobile};
      max-width: ${width.mobile};
      animation: ${animation(`-${width.mobile}`)} 5s forwards;
      &:hover {
        animation-play-state: paused;
      }
    }
  `,
};

export default function Feedback() {
  const { feedback, setFeedback } = useContext(ContextUsuario);

  if (!feedback) return false;

  return (
    <Component.Box
      className="z-3 position-absolute"
      variant={feedback?.variant}
      onAnimationEnd={() => {
        setFeedback(false);
      }}
    >
      <h3>{feedback?.title}</h3>
      {feedback?.description && <p>{feedback?.description}</p>}
    </Component.Box>
  );
}
