import { AiOutlineFileExclamation } from 'react-icons/ai';
import { BiCopy } from 'react-icons/bi';
import {
  BsFillPatchCheckFill,
  BsPatchCheckFill,
  BsQuestionDiamond,
  BsSearch,
  BsWhatsapp
} from 'react-icons/bs';
import { FaDog, FaRegUser } from 'react-icons/fa';
import { FiHome } from 'react-icons/fi';
import { MdSportsSoccer } from 'react-icons/md';
import { TbFileInvoice } from 'react-icons/tb';

export const handleIcons = {
  Home: FiHome,
  Book: AiOutlineFileExclamation,
  Check: BsFillPatchCheckFill,
  About: BsQuestionDiamond,
  Dog: FaDog,
  Ball: MdSportsSoccer,
  Search: BsSearch,
  Ticket: TbFileInvoice,
  User: FaRegUser,
  Whatsapp: BsWhatsapp,
  Copy: BiCopy,
  Result: BsPatchCheckFill
};
