import React, { useEffect, useState } from 'react';
import './main.scss';

export default function Content({ children }) {
  return (
    <main className="col-md overflow-y-auto main ">
      <div>{children}</div>
    </main>
  );
}
