import { useMutation } from '@apollo/client';
import React, { createContext, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FutMutations } from '../../../../api/mutation/fut';
import { useBetContext } from '../../../../context/betContext';
import {
  useContextCart,
} from '../../../../context/context-cart';
import { useContextGameJB } from '../../../../context/context-game-jb';
import { useUsuarioContext } from '../../../../context/context-user';
import handleRouter from '../../../../utils/handle-router';
import { handleText } from '../../../../utils/handle-text';
import Finish from '../../finish';
import TicketMobile from './ticket-mobile';
import TicketMobileJB from './ticketk-mobile-jb';

const TicketContext = createContext({});
export const useTicketContext = () => useContext(TicketContext);

export function Ticket() {
  const { cart, setCart, valorAposta, setValorAposta } = useContextCart();
  const { usuario, setFeedback, setMakeLogin } = useUsuarioContext()
  const [mutationAposta, { loading }] = useMutation(FutMutations.apostar);

  // JB_GAME_DATA
  const { betStates, saveBet } = useBetContext();
  const { changeStep, currentStep } = useContextGameJB();
  const navigate = useNavigate();
  const [loadingJb, setLoadingJb] = useState(false);

  const location = useLocation();
  const isJb = location.pathname.search('jb') == 1;

  function enableTicket(){
    return location.pathname ==  handleRouter.inicio_esportes || location.pathname == handleRouter.inicio_jb  || location.pathname == handleRouter.esportes_partida
  }


  const [form, setForm] = useState({
    forma_pagamento: '',
  });

  const forma_pagamento = [
    {
      label: 'PIX',
      value: 'PIX',
      id: 'group-payment-1',
    },
    {
      label: 'Colaborador',
      value: 'COLABORADOR',
      id: 'group-payment-2',
    },
    ...(usuario?.saldo
      ? [
          {
            label: 'Saldo',
            value: 'SALDO',
            id: 'group-payment-3',
          },
        ]
      : []),
  ];
  function getRawValueAposta() {
    let valor_apostado = 0;
    if (valorAposta) valor_apostado = valorAposta.value;
    return Number(valor_apostado) || 0;
  }

  function calculaCotacaoTotal() {
    let total = 0;
    for (let jogo of cart) {
      total =
        total == 0
          ? jogo?.cotacao_selecionada?.valor
          : total * jogo?.cotacao_selecionada?.valor;
    }
    return total.toFixed(2);
  }

  function getValorApostado() {
    let total = 0;
    if (getRawValueAposta()) {
      total = getRawValueAposta();
    }
    return total;
  }

  function getPossivelRetorno() {
    let possivel_retorno = 0;
    if (getRawValueAposta()) possivel_retorno = getRawValueAposta();
    for (let jogo of cart) {
      if (jogo?.cotacao_selecionada?.valor) {
        possivel_retorno *= 1 * jogo.cotacao_selecionada.valor;
      }
    }
    return possivel_retorno;
  }

  async function apostar() {
    try {
      let { forma_pagamento } = form;

      if (
        (forma_pagamento === 'PIX' || forma_pagamento === 'SALDO') &&
        !usuario
      ) {
        setMakeLogin(true);
        if (!usuario) throw new Error('Faça login para continuar');
      }else  if (forma_pagamento === 'SALDO' && usuario?.saldo < valorAposta.value) {
        throw new Error('Você não possui saldo suficiente');
      }

      let aposta = {
        valor: valorAposta.value,
        forma_pagamento,
      };

      aposta['cotacoes'] = cart.map(item => ({
        id: item.cotacao_selecionada.id,
        valor: item.cotacao_selecionada.valor,
      }));

      let ref = localStorage.getItem('ref');
      if (!!ref) {
        ref = JSON.parse(ref);
        aposta['ref_id'] = ref._id;
      }

      console.log(aposta, '12333');
      let response = await mutationAposta({ variables: { dados: aposta } });
      console.log(response, '@@@@@');
      
      setCart([]);
      setValorAposta('');
      
      navigate( aposta.forma_pagamento == 'COLABORADOR'? `${handleRouter.esportes_compartilhar_codigo_bilhete}?codigos=${response.data.movimentoFutebolSite.cod}&futebol=${false}`:`${handleRouter.conta}?id=${response?.data?.movimentoFutebolSite._id}`)
    } catch (error) {
      console.log(error);
      
      let err = 'Ocorreu um error';
      if (error?.message) err = error.message;
      setFeedback({
        title: 'Error',
        description: err,
        variant: 'danger',
      });
    }
  }

  const header = [
  
    {
      label: 'Jogos',
      valor: cart.length,
    },
    {
      label: 'Cotação',
      valor: calculaCotacaoTotal(),
    },
    {
      label: 'Valor total',
      valor: handleText.asMoney(getValorApostado()),
    },
    {
      label: 'Possível retorno',
      valor: handleText.asMoney(getPossivelRetorno()),
    },
   
  ];

  //DADOS JBBB
  

  function getExtractionsInString() {
    let selecteds = betStates?.extracoes || [];
    if (!selecteds?.length) return '...';
    return selecteds.map(e => e.descricao).join(' ');
  }

  const apostaJB = async () => {
    try {
      setLoadingJb(true);
      let { forma_pagamento } = form;
      if (
        (forma_pagamento === 'PIX' || forma_pagamento === 'SALDO') &&
        !usuario
      ) {
        setMakeLogin(true);
        if (!usuario) throw new Error('Faça login para continuar');
      }
      let total = getTotal();
      if (forma_pagamento === 'SALDO' && usuario.saldo < total)
        throw new Error('Você não possui saldo suficiente');
      let response = await saveBet(forma_pagamento);
      setLoadingJb(false);
      changeStep(1);
      navigate(response?.forma_pagamento == 'COLABORADOR'? `${handleRouter.jb_compartilhar_codigo_bilhete}?codigos=${response.apostas.map(item => item.cod).join('+')}&futebol=${false}`:`${handleRouter.conta}?id=${response.apostas[0]._id}`)
    } catch (error) {
      let err = 'Sem conexão com o servidor.';
      if (error?.message) err = error.message;
      if (typeof error == 'string') err = error;
      setFeedback({
        title: 'Error',
        description: err,
        variant: 'danger',
      });
      setLoadingJb(false);
    }
  };

  function getTotal() {
    return (
      (betStates.jogos_salvo?.total || 0) * (betStates?.extracoes?.length || 0)
    );
  }

  const headerJb = [
    {
      label: 'Jogos',
      valor: betStates.jogos_salvo?.jogos.length,
    },
    {
      label: 'Extração',
      valor: getExtractionsInString(),
    },
    {
      label: 'Data',
      valor: betStates.selectedDate,
    },
    
    {
      label: 'Valor total',
      valor: handleText.asMoney(
        (betStates.jogos_salvo?.total || 0) *
          (betStates?.extracoes?.length || 0)
      ),
    },
  ];

  if(!enableTicket()) return null
  return (
    <TicketContext.Provider
      value={{
        usuario,
        header,
        form,
        loading: loading,
        apostar,
        cart,
        setForm,
        setValorAposta,
        valorAposta,
        //JB
        isJb,
        headerJb,
        getTotal,
        apostaJB,
        loadingJb,
        betStates,
        forma_pagamento
      }}
    >
        <Finish />
      {isJb ? <TicketMobileJB /> : <TicketMobile />}
    </TicketContext.Provider>
  );
}
