import { useMutation } from "@apollo/client";
import React, { useContext, useState } from "react";
import Form from "react-bootstrap/Form";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { FutMutations } from "../../api/mutation/fut";
import { ContextUsuario } from "../../context/context-user";
import { Button } from "../buttons/button";

export function CreateAccount() {

  const { setSessao, setMakeLogin} = useContext(ContextUsuario);
  const [criarContaMutation] = useMutation(FutMutations.criarConta);
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    nome: "",
    email: "",
    cpf: "",
    telefone: "",
    senha: "",
  });

  function isInvalidFormConta() {
    if (form["nome"].length < 4) return true;
    if (validaEmail()) return true;
    if (form["cpf"].length < 11) return true;
    if (form["telefone"].length < 11) return true;
    if (form["senha"].length < 4) return true;
    return false;
  }

  function validaEmail() {
    let regex = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+\.([a-z]+)?$/i;
    return regex.test(form["email"]);
  }

  async function handleSubmit(ev) {
    setLoading(true);
    ev.preventDefault();
    try {
      const _form = ev.currentTarget;
      if (_form.checkValidity() === false) {
        ev.preventDefault();
        ev.stopPropagation();
      }
      setValidated(true);
      if (isInvalidFormConta()) throw new Error("Preencha os dados corretamente!");
      let _data = encodeURI(JSON.stringify(form));
      let { data } = await criarContaMutation({ variables: { data: _data } });
      setSessao(data.futebolsite_criarConta);
      setMakeLogin(false)
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Form noValidate onSubmit={handleSubmit} validated={validated}>
      <div className="row">
        <div className="col-md-12">
          <label htmlFor="nome-input">Nome</label>
          <input
            id="nomecompleto-input"
            type="text"
            className="form-control"
            placeholder="Nome completo"
            required
            minLength={4}
            onChange={(ev) => {
              setForm((_) => ({ ..._, nome: ev.target.value }));
            }}
            value={form["nome"]}
          />
          {form["nome"].length < 4 && (
            <Form.Control.Feedback type="invalid">
              Mínimo 4 caractéres
            </Form.Control.Feedback>
          )}
        </div>
        <div className="col-md-12 mt-3">
          <label htmlFor="email-input">E-mail</label>
          <input
            id="email-input"
            type="email"
            className="form-control"
            placeholder="E-mail"
            required
            onChange={(ev) => {
              setForm((_) => ({ ..._, email: ev.target.value }));
            }}
            value={form["email"]}
          />
          {!validaEmail() && (
            <Form.Control.Feedback type="invalid">
              Email inválido
            </Form.Control.Feedback>
          )}
        </div>
        <div className="col-md-12 mt-3">
          <label htmlFor="cpf-input">CPF</label>
          <InputMask
            id="cpf-input"
            placeholder={"CPF"}
            maskChar={null}
            beforeMaskedValueChange={false}
            mask="999.999.999-99"
            className="form-control"
            required
            pattern="\d{3}\.\d{3}\.\d{3}-\d{2}"
            onChange={(ev) => {
              setForm((_) => ({ ..._, cpf: ev.target.value }));
            }}
            value={form["cpf"]}
          />
          {form["cpf"].length < 14 && (
            <Form.Control.Feedback type="invalid">
              CPF inválido
            </Form.Control.Feedback>
          )}
        </div>
        <div className="col-md-12 mt-3">
          <label htmlFor="fone-input">Celular</label>
          <InputMask
            id="fone-input"
            maskChar={null}
            placeholder={"Telefone"}
            mask="(99)99999-9999"
            className="form-control"
            required
            pattern="\([0-9]{2}\)[0-9]{4,6}-[0-9]{3,4}$"
            minLength={14}
            onChange={(ev) => {
              setForm((_) => ({ ..._, telefone: ev.target.value }));
            }}
            value={form["telefone"]}
          />
          {form["telefone"].length < 14 && (
            <Form.Control.Feedback type="invalid">
              Celular inválido
            </Form.Control.Feedback>
          )}
        </div>
        <div className="col-md-12 mt-3">
          <label htmlFor="password-input">Senha</label>
          <input
            id="password-input"
            type="password"
            className="form-control"
            placeholder="Senha"
            required
            minLength={4}
            onChange={(ev) => {
              setForm((_) => ({ ..._, senha: ev.target.value }));
            }}
            value={form["senha"]}
          />
          {form["telefone"].length < 4 && (
            <Form.Control.Feedback type="invalid">
              Mínimo 4 caractéres
            </Form.Control.Feedback>
          )}
        </div>
        <div className="col-md-12 mt-3">
          <div className="d-flex flex-column">
            <Button type="submit" disabled={loading} loading={loading}>
              Criar
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
}
