import { Container } from 'react-bootstrap';
import costarica from '../../assets/costarica.jpeg';

export function Sobre() {
  return (
    <Container className="py-3 d-grid">
      <h3>Sobre</h3>
      <div className="row  ">
        <p className="col-md-10 col-12">
          Este é um website de entretenimento online que oferece a seus usuários
          uma experiência única em Apostas Esportivas. Este site é operado pela
          empresa Better Games Entertainament, entidade devidamente autorizada
          pelo Governo da Costa Rica, através da licença sob o nº 3-102-808946,
          com sede em Goicochea, Calle Branco, From Catolic Clinic, 25m West,
          Asesores Building, 2nd floor. Ao acessar, continuar a utilizar ou
          navegar no website, você aceita que utilizemos certos cookies de
          navegador com o objetivo de melhorar a sua experiência enquanto
          utiliza nosso site. O website apenas usa cookies para melhorar a sua
          experiência e não interferem com sua privacidade. Quaisquer dúvidas,
          sugestões ou questionamentos podem ser enviados pelo suporte.
        </p>
        <div className="col-md-2 col-12 d-flex justify-content-center">
          <img src={costarica} style={{ width: '12rem', height: '14rem' }} />
        </div>
      </div>
      <hr />
      <h3>Suporte</h3>
      <a className='text-md-start text-center' href="mailto:suporte@tjbet.bet">suporte@tjbet.bet</a>
    </Container>
  );
}
