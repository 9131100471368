import React from "react";
import { BiFootball } from "react-icons/bi";
import styled, { keyframes } from "styled-components";

const updown = keyframes`
    from {
        transform: translateY(0rem) rotate(0deg);
    }
    to {
        transform: translateY(-5rem) rotate(360deg);
    }
`;

const LoadingMainComponent = styled.div`
  height: 60vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 2;
  gap: 0.5rem;
  .icon {
    color: ${({ theme }) => theme.primary_light};
    font-size: 2rem;
    animation: ${updown} 1s alternate infinite;
    &:nth-of-type(2) {
      animation-delay: 0.3s;
    }
    &:nth-of-type(3) {
      animation-delay: 0.6s;
    }
  }
`;

export default function LoadingBalls() {
  return (
    <LoadingMainComponent>
      <BiFootball className="icon" />
      <BiFootball className="icon" />
      <BiFootball className="icon" />
    </LoadingMainComponent>
  );
}
