import React from 'react';
import { Button } from 'react-bootstrap';
import BSTable from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';
import { useContextCart } from '../../context/context-cart';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import handleRouter from '../../utils/handle-router';
import defaultShield from '../../assets/default.png';
const headers_cotacoes = [1, 'x', 2];

export default function TableHome({ data, date }) {
  const { addToCart, cart } = useContextCart();
  const eventos_fut = data?.getEventosFutebol;
  const navigate = useNavigate();
  const viewMoreCot = id => {
    navigate(`${handleRouter.esportes_partida}?id=${id}`);
  };
  const { isMd } = useWindowDimensions();

  function truncateText(text, maxLength) {
    if (isMd) return text;
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.substring(0, maxLength - 3) + '...';
    }
  }

  function formatLiga(liga) {
    liga = liga.split('-');
    let pais = liga.splice(0, 1);

    return {
      pais,
      liga,
    };
  }

  return (
    <div className="d-flex flex-column  ">
      {eventos_fut?.map(item => (
        <BSTable className="m-0  " key={item.liga}>
          <thead className="bg-dark bg-gradient  ">
            <tr>
              <th
                style={{ verticalAlign: 'middle' }}
                className="col-7 text-start bg-dark bg-gradient text-light  "
              >
                <div className="d-flex flex-column">
                <small>{formatLiga(item.liga).pais}</small>
                  <small>{formatLiga(item.liga).liga}</small>
                </div>
              </th>
              {headers_cotacoes.map(item => (
                <th
                  style={{ verticalAlign: 'middle' }}
                  key={item}
                  className="col justify-content-center align-items-center   text-center bg-dark  bg-gradient text-light  "
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {item?.partidas?.map(partida => {
              let selecionada = cart?.find(c => c._id === partida._id);
              let mais_cotacoes =
                selecionada &&
                selecionada?.cotacao_selecionada?.sigla?.split(';')?.[1] !==
                  'Vencedor da partida';
              return (
                <tr key={partida._id}>
                  <td
                    className={'col-12 btn btn-light  '}
                    onClick={() => viewMoreCot(partida.base_id)}
                  >
                    <div className="d-grid text-start ">
                      <div className="row">
                        <div className="col-md-1 col-1">
                          <img
                            src={partida?.casa_img || defaultShield}
                            style={{ width: 20, height: 20,  }}
                          />
                        </div>
                        <small className="col fw-bold" style={{fontSize: !isMd? '.8rem':'.9rem'}}>
                       { partida.casa}
                        </small>
                      </div>
                      <div className="row">
                        <div className="col-1">
                          <img
                            src={partida?.fora_img || defaultShield}
                            style={{ width: 20, height: 20 }}
                          />
                        </div>
                        <small className="col fw-bold" style={{fontSize: !isMd? '.8rem':'.9rem'}}>
                          {partida.fora}
                        </small>
                      </div>
                      <div className="row">
                        <div className="col-1"></div>
                        <small
                          className="col fw-bold"
                          style={{ fontSize: '0.7rem' }}
                        >
                          {partida.data_hora_br}{' '}
                          {mais_cotacoes ? (
                            <small className="badge text-bg-secondary text-light">
                              {selecionada?.cotacao_selecionada?.valor}
                            </small>
                          ) : (
                            <strong>
                              {'+' + partida.cotacoes_principais.mais}
                            </strong>
                          )}
                        </small>
                      </div>
                    </div>
                  </td>
                  {partida.cotacoes_principais?.cotacoes?.map(cotacao => (
                    <td
                      className=" m-0  border-0 "
                      onClick={ev => {
                        ev.stopPropagation();
                        addToCart(ev, {
                          ...partida,
                          cotacao_selecionada: cotacao,
                        });
                      }}
                      style={{ verticalAlign: 'middle', padding: 2 }}
                      key={cotacao.id}
                    >
                      <Button
                        className={`col-12  py-3 text-center   rounded   ${
                          selecionada?.cotacao_selecionada?.id === cotacao.id
                            ? 'btn-secondary'
                            : 'btn-primary'
                        }`}
                      >
                        <b style={{ fontSize: '0.8rem' }}>
                          {cotacao.valor.toFixed(2)}
                        </b>
                      </Button>
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </BSTable>
      ))}
    </div>
  );
}
