import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useBetContext } from '../../../../context/betContext';
import { useContextGameJB } from '../../../../context/context-game-jb';
import { handleText } from '../../../../utils/handle-text';
import CardFinalizaApostaJB from '../../../cards/card-finaliza-aposta-jb';
import { LabelValor } from '../../finish/components/label-valor';
import { useTicketContext } from './ticket';

export default function TicketMobileJB() {
  const [showTicket, setShowTicket] = useState(false);
  const {
    form,
    setForm,
    usuario,
    apostaJB,
    loadingJb,
    headerJb,
    forma_pagamento,
  } = useTicketContext();
  const { currentStep } = useContextGameJB();
  const { betStates } = useBetContext();
  const { id } = currentStep;

  if (id == 1) return null;
  if (!showTicket) {
    return (
      <div className="bottom-0 bg-primary-main  z-3 w-100 position-fixed  d-md-none d-grid p-1 gap-2 text-light align-items-center ">
        <div className="d-flex  gap-2">
          <div className="col d-flex flex-column text-center  ">
            <small>Valor total</small>
            <small className="fw-bold ">{headerJb[3].valor}</small>
          </div>
          <div className="col  d-flex align-items-center ">
            <Button
              style={{ fontSize: '.8rem' }}
              className=" btn btn-primary w-100 h-100  rounded-2"
              disabled={!betStates.jogos_salvo?.jogos.length}
              onClick={async () => setShowTicket(true)}
            >
              {`Cupom `}
              {betStates.jogos_salvo?.jogos.length > 0 && (
                <span className=" badge text-bg-secondary">
                  {betStates.jogos_salvo?.jogos.length}
                </span>
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <Modal
        show={showTicket}
        fullscreen={true}
        onHide={() => setShowTicket(false)}
      >
        <Modal.Header
          className="bg-primary-main text-light py-2"
          closeButton
          closeVariant="white"
        >
          <Modal.Title>Cupom</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-2 py-1">
          <div className=" d-flex flex-column gap-1 ">
            {betStates.jogos_salvo?.jogos.map((jogo, index) => (
              <CardFinalizaApostaJB
                key={String(index).toString()}
                item={jogo}
                index={index}
              />
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer className=" d-block  bg-primary-main text-light py-0">
          <b className="text-start text-light">Formas de pagamento</b>
          <Form>
            {forma_pagamento.map(item => (
              <Form.Check
                checked={item.value === form.forma_pagamento}
                onChange={ev =>
                  setForm(_ => ({
                    ..._,
                    forma_pagamento: item.value,
                  }))
                }
                key={item.id}
                inline
                label={item.label}
                name={'group1'}
                type={'radio'}
                id={`inline-${item.id}`}
              />
            ))}
          </Form>
          <div className=" ">
            {headerJb.map(item => (
              <LabelValor {...item} key={item.label} />
            ))}
            {form.forma_pagamento === 'SALDO' && (
              <LabelValor
                label={'Saldo'}
                valor={handleText.formatValueToMoney(usuario?.saldo)}
              />
            )}
          </div>
          <div className="d-grid   ">
            <button
              className="btn btn-primary "
              disabled={loadingJb || !betStates.jogos_salvo?.jogos.length}
              onClick={apostaJB}
            >
              {(form['forma_pagamento'] === 'PIX' ||
                form['forma_pagamento'] === 'SALDO') &&
                !usuario &&
                'FAZER LOGIN'}
              {(form['forma_pagamento'] === 'PIX' ||
                form['forma_pagamento'] === 'SALDO') &&
                usuario &&
                'FINALIZAR'}
              {(!form['forma_pagamento'] ||
                form['forma_pagamento'] === 'COLABORADOR') &&
                'FINALIZAR'}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
