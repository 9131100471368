import { useState } from "react";

export default function usePagination(initial_value) {
  const default_pagination = { busca: "", pagina: 1, porpagina: 50 };
  const [pagination, setPagination] = useState({
    ...default_pagination,
    ...initial_value,
    filtros: JSON.stringify(initial_value?.filtros ?? {}),
  });

  const changeFieldValue = (key, value) =>
    setPagination((old) => {
      old[key] = value;
      if (key === "busca") old.pagina = default_pagination.pagina;
      return old;
    });

  const updatePage = () =>
    setPagination((old) => ({ ...old, pagina: old.pagina + 1 }));

  return { pagination, changeFieldValue, updatePage };
}
