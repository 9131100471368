import dayjs from 'dayjs';
export const handleText = {
  formatValueToMoney: v => `R$${(!v || Number.isNaN(v) ? 0 : v).toFixed(2)}`,
  onlyNumber(value) {
    if (!value) return '';
    return value.replace(/\D/g, '');
  },
  someWords: (value = '', limit = 62) =>
    value
      ? value.length <= limit
        ? value
        : `${value.substring(0, limit)}...`
      : '',
  cpf: (value = '') => {
    if (!value) return '';
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  },

  isNumber: value => isNaN(value) === false,

  asMoney: value =>
    new Intl.NumberFormat('pt-br', {
      currency: 'BRL',
      style: 'currency',
    }).format(handleText.isNumber(value) ? Number(value) : 0),

  zeroEsquerda(zeros, text) {
    text = text + '';
    let resp = '';
    let size = zeros - text.length;
    for (let i = 0; i < size; i++) {
      resp += '0';
    }
    resp += text;
    return resp;
  },
  moneyBr(text) {
    if (!text) return '';
    let money = String(Number(this.onlyNumber(String(text))));
    if (Number(money) > 9999999999999) money = '0';
    if (money.length < 3) money = this.zeroEsquerda(3, money);
    money = String(money).replace(/([0-9]{2})$/g, ',$1');
    if (money.length > 6)
      money = money.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
    return money;
  },
  toMoneyFormat(value) {
    // let text = (value < 0) ? "-" : ""
    // return text + MaskService.toMask('money', value, {
    //   separator: ',',
    //   delimiter: '.',
    //   unit: '',
    //   suffixUnit: '',
    //   precision: 2,
    // });
  },
  dateBRToUS(data) {
    return data.split('/').reverse().join('-');
  },
  moneyBrToUs(text) {
    return Number(
      Number((text || '0').split('.').join('').split(',').join('.')).toFixed(2)
    );
  },
  getDayFromBRToUS(data = new Date()) {
    return dayjs(this.dateBRToUS(data)).day();
  },
  premioLabel(array) {
    let label = '';
    let ultimo = null;
    array.map((p, i) => {
      let ant = Number(array[i - 1]);
      let prx = Number(array[i + 1]);
      p = Number(p);
      if (!ant) {
        label += p;
        return true;
      }
      if (p - 1 == ant && prx != p + 1) {
        label += 'a' + p;
        return true;
      }
      if (p - 1 == ant && p + 1 == prx) {
        return true;
      }
      if (prx == p + 1 && ant != p - 1) {
        label += ',' + p;
        return true;
      }
      if (prx != p + 1 && ant != p - 1) {
        label += ',' + p;
        return true;
      }
      if (!prx && ant != p - 1) {
        label += ',' + p;
        return true;
      }
    });
    return label;
  },

  maskNumber(
    value = 0,
    precision = 2,
    decimalSeparator = ',',
    thousandSeparator = '.',
    allowNegative = false,
    prefix = 'R$',
    suffix = ''
  ) {
    if (precision === void 0) precision = 2;
    if (decimalSeparator === void 0) decimalSeparator = '.';
    if (thousandSeparator === void 0) thousandSeparator = ',';
    if (allowNegative === void 0) allowNegative = false;
    if (prefix === void 0) prefix = '';
    if (suffix === void 0) suffix = '';

    // provide some default values and arg validation.
    if (precision < 0) {
      precision = 0;
    } // precision cannot be negative
    if (precision > 20) {
      precision = 20;
    } // precision cannot be greater than 20

    if (value === null || value === undefined) {
      return {
        value: 0,
        maskedValue: '',
      };
    }

    value = String(value); //if the given value is a Number, let's convert into String to manipulate that

    if (value.length == 0) {
      return {
        value: 0,
        maskedValue: '',
      };
    }

    // extract digits. if no digits, fill in a zero.
    var digits = value.match(/\d/g) || ['0'];

    var numberIsNegative = false;
    if (allowNegative) {
      var negativeSignCount = (value.match(/-/g) || []).length;
      // number will be negative if we have an odd number of "-"
      // ideally, we should only ever have 0, 1 or 2 (positive number, making a number negative
      // and making a negative number positive, respectively)
      numberIsNegative = negativeSignCount % 2 === 1;

      // if every digit in the array is '0', then the number should never be negative
      var allDigitsAreZero = true;
      for (var idx = 0; idx < digits.length; idx += 1) {
        if (digits[idx] !== '0') {
          allDigitsAreZero = false;
          break;
        }
      }
      if (allDigitsAreZero) {
        numberIsNegative = false;
      }
    }

    // zero-pad a input
    while (digits.length <= precision) {
      digits.unshift('0');
    }

    if (precision > 0) {
      // add the decimal separator
      digits.splice(digits.length - precision, 0, '.');
    }

    // clean up extraneous digits like leading zeros.
    digits = Number(digits.join('')).toFixed(precision).split('');
    var raw = Number(digits.join(''));

    var decimalpos = digits.length - precision - 1; // -1 needed to position the decimal separator before the digits.
    if (precision > 0) {
      // set the final decimal separator
      digits[decimalpos] = decimalSeparator;
    } else {
      // when precision is 0, there is no decimal separator.
      decimalpos = digits.length;
    }

    // add in any thousand separators
    for (var x = decimalpos - 3; x > 0; x = x - 3) {
      digits.splice(x, 0, thousandSeparator);
    }

    // if we have a prefix or suffix, add them in.
    if (prefix.length > 0) {
      digits.unshift(prefix);
    }
    if (suffix.length > 0) {
      digits.push(suffix);
    }

    // if the number is negative, insert a "-" to
    // the front of the array and negate the raw value
    if (allowNegative && numberIsNegative) {
      digits.unshift('-');
      raw = -raw;
    }

    return {
      value: raw,
      maskedValue: digits.join('').trim(),
    };
  },
};
