import React, { useEffect } from 'react';
import { useContextGameJB } from '../../context/context-game-jb';

export default function Jb() {
  const { currentStep } = useContextGameJB();
  const { Component, id } = currentStep;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  return (
      <Component />

  );
}
