import React from 'react';
import { Form } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import { handleText } from '../../../utils/handle-text';
import { Button } from '../../buttons/button';
import CardFinalizaAposta from '../../cards/card-finaliza-aposta';
import CardFinalizaApostaJB from '../../cards/card-finaliza-aposta-jb';
import { useTicketContext } from '../main/components/ticket';
import { LabelValor } from './components/label-valor';

export default function Finish() {
  const {
    usuario,
    header,
    form,
    loading,
    apostar,
    cart,
    setForm,
    setValorAposta,
    valorAposta,
    // JB
    isJb,
    headerJb,
    apostaJB,
    loadingJb,
    betStates,
    forma_pagamento
  } = useTicketContext();

  return (
    <aside
    style={{ height: '100vh' }}
    className="col-md-3 d-none d-md-flex  flex-column  ">
      <div
        className="overflow-y-auto px-2   d-flex flex-column gap-2 py-3"
        style={{ background: '#fff', height: '100%' }}
      >
        {isJb
          ? betStates.jogos_salvo?.jogos.map((jogo, index) => (
              <CardFinalizaApostaJB
                key={String(index).toString()}
                item={jogo}
                index={index}
              />
            ))
          : cart?.map(partida => (
              <CardFinalizaAposta {...{ partida }} key={partida?._id} />
            ))}
      </div>

      <div className=" text-light p-2  bg-primary   rounded-top ">
        {(isJb ? headerJb : header).map(item => (
          <LabelValor {...item} key={item.label} />
        ))}
        {form.forma_pagamento === 'SALDO' && (
          <LabelValor
            label={'Saldo'}
            valor={handleText.formatValueToMoney(usuario?.saldo)}
          />
        )}
        <Form.Group>
          {!isJb && (
              <input
                value={valorAposta.maskedValue}
                className="form-control form-control-sm"
                placeholder={'Valor'}
                onChange={({ target }) =>
                  setValorAposta(handleText.maskNumber(target.value))
                }
              />
          )}

          <div className="my-2">
            <Form.Label
              title="Formas de pagamento"
              children="Formas de pagamento"
              className="m-0  fw-semibold"
            />

            <Form>
              {forma_pagamento.map(item => (
                <Form.Check
                  checked={item.value === form.forma_pagamento}
                  onChange={ev =>
                    setForm(_ => ({
                      ..._,
                      forma_pagamento: item.value,
                    }))
                  }
                  key={item.id}
                  label={item.label}
                  name={'group1'}
                  type={'radio'}
                  id={`inline-${item.id}`}
                />
              ))}
            </Form>
          </div>
        </Form.Group>

        <div className="d-flex flex-column pb-2">
          <Button
            variant="light"
            onClick={isJb ? apostaJB : apostar}
            disabled={loadingJb || loading}
          >
            {(form['forma_pagamento'] === 'PIX' ||
              form['forma_pagamento'] === 'SALDO') &&
              !usuario &&
              'FAZER LOGIN'}
            {(form['forma_pagamento'] === 'PIX' ||
              form['forma_pagamento'] === 'SALDO') &&
              usuario &&
              'FINALIZAR'}
            {(!form['forma_pagamento'] ||
              form['forma_pagamento'] === 'COLABORADOR') &&
              'FINALIZAR'}
          </Button>
        </div>
      </div>
    </aside>

  );
}
