import React from 'react';
import { useBetContext } from '../../../context/betContext';
import { TJInput } from '../Finaliza';

export const TipoJogoInput = props => {
  const { betStates, changeOneValueInput } = useBetContext();
  console.log(betStates.tipojogo);
  
  if (!['I', 'S', 'C'].includes(betStates?.tipojogo?.tipo)) return null;
  return (
    <TJInput
      disabled={betStates.disableInput}
      placeholder={'Escolher números'}
      value={betStates.oneInput}
      type={'text'}
      maxLength={betStates.tipojogo.caracter_max_form}
      onEnded={() => changeOneValueInput(betStates.oneInput, true)}
      onChange={valor => changeOneValueInput(valor.target.value, false)}
    />
  );
};
