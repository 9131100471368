import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useBetContext } from '../../context/betContext';
import { useContextGameJB } from '../../context/context-game-jb';
import config from '../../utils/config';

export default function TiposJogos() {
  let [father, setFather] = useState(0);
  const { betStates, changeBetState } = useBetContext();
  const [selectedGamesList, setSelectedGamesList] = useState([]);
  const { changeStep } = useContextGameJB();

  const selectGameOption = item => {
    setFather(item.id);
    setSelectedGamesList(prev => {
      prev.push(item);
      return prev;
    });
  };

  const unselectGame = item => {
    let aux = [];
    setFather(item.pai);

    function getSelectedSons(_father, elements) {
      let son = elements.filter(e => _father.id == e.pai)[0];
      aux.push(_father.id);
      if (son) return getSelectedSons(son, elements);
    }

    getSelectedSons(item, selectedGamesList);
    aux = selectedGamesList.filter(tj => !aux.includes(tj.id));
    setSelectedGamesList([...aux]);
  };

  const handleCompleteGame = () => {
    setSelectedGamesList([]);
    setFather(0);
    changeBetState({
      selectedGames: selectedGamesList,
    });
    changeStep(3);
  };

  const limpar = () => {
    setSelectedGamesList([]);
    setFather(0);
  };

  return (
    <div className="container d-grid py-1 px-0">
    

      <div className="col col-md-8">
        <div className="card    text-bg-light">
          {!selectedGamesList.length && (
            <>
              <div className="card-header fw-bold  text-bg-dark  bg-gradient">
                <small>
                  {selectedGamesList.length
                    ? 'Combinações'
                    : 'Modalidades favoritas'}
                </small>
              </div>
              <div className="card-body m-0 p-0 py-3">
                <div className=" row m-0 p-0">
                  {[
                    betStates.gamesTypes[0],
                    betStates.gamesTypes[5],
                    betStates.gamesTypes[8],
                    betStates.gamesTypes[18],
                  ].map((item, index) => {
                    if (item.pai == father) {
                      return (
                        <TJButtonTipoJogo
                          key={index.toString()}
                          onClick={() => selectGameOption(item)}
                          {...item}
                        />
                      );
                    }
                  })}
                </div>
              </div>
            </>
          )}
          {!!selectedGamesList.length && (
            <>
              <div className="card-header fw-bold  text-bg-dark  bg-gradient">
                <small>Modalidade</small>
              </div>
              <div className="card-body  m-0 p-0 py-3">
                <div className=" row m-0 p-0">
                  {selectedGamesList.map((item, index) => {
                    return (
                      <TJButtonTipoJogo
                        {...item}
                        onClick={() => unselectGame(item)}
                        key={index.toString()}
                      />
                    );
                  })}
                </div>
              </div>
            </>
          )}

          <div className="card-header fw-bold  text-bg-dark  bg-gradient">
            <small>
              {selectedGamesList.length ? 'Combinações' : 'Modalidades'}
            </small>
          </div>
          <div className="card-body m-0 p-0 py-3">
            <div className=" row m-0 p-0">
              {betStates.gamesTypes.map((item, index) => {
                if (item.pai == father) {
                  return (
                    <TJButtonTipoJogo
                      key={index.toString()}
                      onClick={() => selectGameOption(item)}
                      {...item}
                    />
                  );
                }
              })}
            </div>
          </div>

          <div className="card-footer ">
            <div className="d-grid  ">
              <div className="row gap-2  justify-content-center">
                <Button
                  className="col fw-bold"
                  variant="secondary"
                  onClick={() =>
                    selectedGamesList.length ? limpar() : changeStep(1)
                  }
                >
                  Voltar
                </Button>
                <Button
                  disabled={!selectedGamesList.length}
                  variant="primary"
                  className="col   fw-bold"
                  onClick={handleCompleteGame}
                >
                  Continuar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function TJButtonTipoJogo(item) {
  function clearDescription(descricao = '...') {
    return descricao.split('*').join('').trim();
  }

  return (
    <div onClick={item.onClick} className="col-3 p-1">
      <div
        className={`shadow w-100 h-100  pointer rounded-3   d-flex   p-1 flex-column  align-items-start justify-content-between`}
        style={{
          minHeight: 73,
          lineHeight: 1,
          backgroundColor: item.selecionado
            ? config.colors.secondary
            : config.colors.white_FF,
          color: item.selecionado
            ? config.colors.white_FF
            : config.colors.black,
        }}
      >
        <span className="badge text-bg-primary text-light ">
          {item?.valor_premio || ''}x
        </span>
        <small className="fw-bold ">
          {item?.descricao && (
            <small all style={{ fontSize: 12 }} className="text-center">
              {clearDescription(item?.descricao)}
            </small>
          )}
        </small>
      </div>
    </div>
  );
}
