import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Content from './components/content';
import Feedback from './components/feedback';
import Header from './components/header';
import Session from './components/session';
import Sidebar from './components/sidebar';
import { Ticket } from './components/ticket';
import handleRouter from '../../../utils/handle-router';

export default function MainLayout() {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if(location.pathname =='/'){
      navigate(handleRouter.inicio_esportes)
    }
  },[])

  return (
    <div className="d-grid ">
      <Header />
      <div className="row g-0">
        <Sidebar />
        <Content>
          <Outlet />
        </Content>
        <Ticket /> 
      </div>
      <Session />
      <Feedback />
    </div>
  );
}
