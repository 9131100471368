import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { gameRequests } from '../../requests/gameRequests';
import dayjs from 'dayjs';

export default function ResultadoJB() {
  let today = dayjs();
  const [resultado, setResultado] = useState([]);
  const [data, setData] = useState(today.format('YYYY-MM-DD'));
  const [sorteios, setSorteios] = useState();

  let weekDays = [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
  ];

  function getProgramacao() {
    let programacao = [
      dayjs().add(-2, 'day').startOf('D'),
      dayjs().add(-1, 'day').startOf('D'),
      dayjs().startOf('D'),
    ];

    let dia = programacao.map(dia => {
      let d = dia.get('d');
      return {
        label: today.get('d') === d ? 'Hoje' : weekDays[d],
        value: dia.format('YYYY-MM-DD'),
      };
    });

    return dia;
  }

  async function getResultado() {
    try {
      let resultado = await gameRequests.getResultadoJB(data);
      setSorteios();
      setResultado(resultado);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getResultado();
  }, [data]);

  function renderLocais() {
    return resultado.map((item, index) => (
      <div key={index} className="col">
        <Button
          style={{ lineHeight: 1 }}
          className="w-100 h-100"
          onClick={() => setSorteios(item)}
          variant={item?.local == sorteios?.local ? 'secondary' : 'primary'}
        >
           <small className="fw-bold" style={{ fontSize: '0.7rem' }}>
              {item.local.split('/')[1]}
            </small>
            <br />

            <small className="fw-bold text-capitalize " style={{ fontSize: '0.7rem' }}>
              {String(item.local.split('/')[0]).toLocaleLowerCase() }
            </small>

        </Button>
      </div>
    ));
  }

  return (
    <div className="container px-0 d-grid py-1 gap-1">
      <div className="d-flex flex-row gap-2 mx-2">
        {getProgramacao().map((dia, index) => (
          <Button
            size="sm"
            key={index}
            variant={dia.value == data ? 'primary' : 'dark'}
            className={`bg-gradient text-uppercase text-white   bg-primary-sm`}
            onClick={() => setData(dia.value)}
          >
            {dia.label}
          </Button>
        ))}
      </div>
      <div className="col col-md-12">
        <div className="card text-bg-light text-dark ">
          <div className="card-header fw-bold text-bg-dark  bg-gradient">
            <small>Estado</small>
          </div>
          <div className="card-body gap-2 d-grid ">
            <div className="d-flex gap-2">{renderLocais()}</div>
            <div className="row g-2">
              {sorteios?.sorteios?.map((sorteio, index) => {
                return (
                  <div key={index} className="col-12 col-md-3    ">
                    <table key={index} className="border table table-sm  ">
                      <thead className="text-center bg-dark bg-gradient text-light">
                        <tr className=" bg-dark">
                          <th
                            colSpan={3}
                            className="bg-dark bg-gradient text-light"
                          >
                            {sorteio.extracao.descricao}
                          </th>
                        </tr>
                      </thead>
                      <thead className="text-center">
                        <tr>
                          <th>PRÊMIO</th>
                          <th>NÚMEROS</th>
                          <th>GRUPO</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {sorteio.premios.map((p, index) => (
                          <tr key={index}>
                            <th scope="row">{p.premio}º</th>
                            <td>
                              {p.dados}-{p.grupo}
                            </td>
                            <td>{p.bicho}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
