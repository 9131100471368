import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { AiOutlineClose } from 'react-icons/ai';
import QRCode from 'react-qr-code';
import styled from 'styled-components';
import { UserQueries } from '../../../api/query/user';
import LoadingBalls from '../../../components/loading/loading-balls';
import { useTimer } from '../../../hooks/useTimer';
import { handleText } from '../../../utils/handle-text';
import resolverTexts from '../../../utils/resolver-texts';
import { STATUS_PREMIO_FUTEBOL_COLOR } from '../modules/status_premio_futebol';
import { Button } from 'react-bootstrap';
import { handleIcons } from '../../../utils/handle-icon';
const Components = {
  BackModal: styled.div`
    background-color: ${({ theme }) => theme.palette_common.shadow};
    padding: ${({ theme }) => theme.space_size.md};
    align-items: center;
    backdrop-filter: blur(0.2rem);
    position: fixed;
    inset: 0px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: start;
  `,
  Box: styled.div`
    overflow-y: auto;
    max-width: 90%;
    max-height: 80%;
  `,
  Image: styled.img`
    width: 60%;
    aspect-ratio: 1;
    align-self: center;
    position: relative;
    margin-bottom: ${({ theme }) => theme.space_size.md};
  `,
  Code: styled.p`
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: white;
    font-weight: bold;
    transition: 0.4s;
    color: ${({ theme, copied }) =>
      copied ? theme.palette.success.main : theme.palette.third.main};
    padding: ${({ theme }) => theme.space_size.sm};
    margin-bottom: ${({ theme }) => theme.space_size.sm};
    border-radius: ${({ theme }) => theme.space_size.sm};
    border: ${({ copied, theme }) =>
      '1px solid' +
      (copied ? theme.palette.success.main : theme.palette_common.opaque)};
    p {
      text-overflow: ellipsis;
      overflow-x: hidden;
      max-width: 30vw;
    }
  `,
  Row: styled.div`
    display: grid;
    font-size: 0.7rem;
    align-items: center;
    gap: ${({ theme }) => theme.space_size.md};
    grid-template-columns: ${({ tamanho = '1fr 1fr' }) => tamanho};
  `,
  Jogo: styled.div``,
  BoxHelper: styled.p`
    color: ${({ theme }) => theme.palette.primary.text};
    padding: 1rem;
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-radius: 0.5rem;
    margin-block: 0.5rem;
  `,
};

export function ModalDetalhado({ onClose, ...apostas }) {
  const [copied, setCopied] = useState(false);
  const [copiedCode, setCopiedCode] = useState(false);

  const [isPix, setIsPix] = useState(false);
  const [dataExibicao, setDataExibicao] = useState('');

  const { data } = useQuery(UserQueries.getApostaDetalhadaUsuarioSite, {
    // skip: !apostas._id,
    fetchPolicy: 'network-only',
    pollInterval: 1000,
    variables: {
      _id: apostas._id,
    },
    onError(err) {
      console.log(JSON.stringify(err, null, 2), 'ERRR');
    },
  });
  const aposta_detalhada = data?.getApostaDetalhadaUsuarioSite;
  const pixCreatedAt = dayjs(
    Number(aposta_detalhada?.pagstar_pix?.generated_at)
  );
  const pixExpireAt = dayjs(Number(aposta_detalhada?.pagstar_pix?.expire_at));
  const { time, isExpired } = useTimer(pixExpireAt);

  useEffect(() => {
    if (aposta_detalhada?._id && aposta_detalhada?.forma_pagamento == 'PIX')
      setIsPix(true);
  }, [aposta_detalhada]);

  useEffect(() => {
    if (!!aposta_detalhada?.data_movimento) {
      let _data = Number(aposta_detalhada.data_movimento);
      if (aposta_detalhada?.futebol) {
        _data = dayjs(_data).format('DD/MM/YY HH:mm:ss');
        setDataExibicao(_data);
      } else {
        _data = dayjs(_data).toISOString();
        _data = _data.split('T')[0].split('-').reverse().join('/');
        setDataExibicao(_data);
      }
    }
  }, [aposta_detalhada?.data_movimento]);

  if (!aposta_detalhada) {
    return (
      <Components.BackModal>
        <LoadingBalls />
      </Components.BackModal>
    );
  }
  return (
    <Components.BackModal onClick={onClose}>
      <Components.Box
        className="card col-12 col-md-4 gap-1 p-3"
        onClick={ev => {
          ev.preventDefault();
          ev.stopPropagation();
        }}
      >
        <div className="d-flex  align-items-center justify-content-between">
          <div className="fw-bold fs-6">
            {aposta_detalhada?.cod} -{' '}
            {aposta_detalhada.futebol ? 'Futebol' : 'JB'}
          </div>

          <button onClick={onClose} className="btn btn-sm btn-danger ">
            <AiOutlineClose className="fs-6" />
          </button>
        </div>
        {/* 
        // <Components.BoxHelper className="text-center">
            //   Informe o código do bilhete: <b>{aposta_detalhada?.cod}</b> para o colaborador parceiro para validar seu jogo. */}
        {/* // </Components.BoxHelper> */}
        {aposta_detalhada?.forma_pagamento == 'COLABORADOR' &&
          aposta_detalhada?.status == 'AGUARDANDO VALIDACAO' && (
            <div className="card card-body text-bg-primary">
              <p className="  text-white text-center  ">
                <b style={{ color: 'yellow' }}>ATENÇÃO!</b>
                <br />
                Esse bilhete precisa ser validado em um colaborador com o código
                abaixo: <br />
                <b className="fs-5">{aposta_detalhada.cod}</b>
              </p>
              <div className="d-grid gap-2 col mx-auto">
                <Button
                  target="_blank"
                  href={`https://wa.me/?text=${aposta_detalhada.cod}`}
                  variant="primary-main"
                >
                  {'Compartilhar pelo whatsapp '}
                  <handleIcons.Whatsapp size={25} />
                </Button>

                <CopyToClipboard
                  text={aposta_detalhada.cod}
                  onCopy={() => setCopiedCode(true)}
                >
                  <Button
                    variant={copied ? 'success' : 'primary-main'}
                    className="btn-primary-main "
                  >
                    {copiedCode ? (
                      <>
                        {`Código copiado `}
                        <handleIcons.Check size={25} />
                      </>
                    ) : (
                      <>
                        {`Copiar código `}
                        <handleIcons.Copy size={25} />
                      </>
                    )}
                  </Button>
                </CopyToClipboard>
              </div>
            </div>
          )}
        <hr />
        <h5 className="text-center">
          {resolverTexts(aposta_detalhada?.status)}
        </h5>

        {isPix &&
          !isExpired &&
          aposta_detalhada?.pagstar_pix?.pix_key &&
          aposta_detalhada.status == 'AGUARDANDO VALIDACAO' && (
            <div className="d-flex flex-column text-center">
              <h6>Expira em: {time}</h6>
              <CopyToClipboard
                text={aposta_detalhada?.pagstar_pix?.pix_key || ''}
                onCopy={() => setCopied(true)}
              >
                <Components.Code {...{ copied }}>
                  <Components.Row tamanho={'1fr auto'}>
                    <p>{aposta_detalhada?.pagstar_pix?.pix_key}</p>
                    <button className="btn btn-primary">
                      {copied ? 'Copiado' : 'Copiar'}
                    </button>
                  </Components.Row>
                </Components.Code>
              </CopyToClipboard>
              <div className="d-flex flex-column align-items-center my-2">
                <QRCode
                  size={144}
                  value={aposta_detalhada?.pagstar_pix?.pix_key}
                />
              </div>
            </div>
          )}
        <Components.Row>
          {/* <div>Forma de pagamento</div>
          <div className="text-end fw-semibold">
            {aposta_detalhada.forma_pagamento}
          </div> */}
        </Components.Row>

        <Components.Row>
          <div>Jogos</div>
          <div className="text-end fw-semibold">
            {aposta_detalhada?.jogos?.length || aposta_detalhada?.jogos_futebol?.length}
          </div>
        </Components.Row>

        {!aposta_detalhada.futebol && (
          <>
            <Components.Row>
              <div>Extração</div>
              <div className="text-end fw-semibold">
                {aposta_detalhada.extracao.descricao}
              </div>
            </Components.Row>
            <Components.Row>
              <div>Data</div>
              <div className="text-end fw-semibold">{dataExibicao}</div>
            </Components.Row>
          </>
        )}

        {aposta_detalhada.futebol && (
          <Components.Row>
            <div>Cotação</div>
            <div className="text-end fw-semibold">
              {aposta_detalhada.cotacao_bilhete.toFixed(1)}
            </div>
          </Components.Row>
        )}

        <Components.Row>
          <div>Valor total</div>
          <div className="text-end fw-semibold">
            {handleText.asMoney(aposta_detalhada.valor_bruto)}
          </div>
        </Components.Row>

        {!!aposta_detalhada?.valor_premios && (
          <Components.Row>
            <div>Valor do prêmio</div>
            <div className="text-end fw-semibold">
              {handleText.asMoney(aposta_detalhada.valor_premios)}
            </div>
          </Components.Row>
        )}

        {aposta_detalhada.futebol && (
          <Components.Row>
            <div>Possível retorno</div>
            <div className="text-end fw-semibold">
              {handleText.asMoney(aposta_detalhada.possivel_premio)}
            </div>
          </Components.Row>
        )}

        {aposta_detalhada.status != 'AGUARDANDO VALIDACAO' && (
          <div
            className={`badge text-bg-${
              STATUS_PREMIO_FUTEBOL_COLOR[
                aposta_detalhada.status_premio_futebol
              ]
            } text-light my-2`}
          >
            {aposta_detalhada.status_premio_futebol}
          </div>
        )}

        {aposta_detalhada.valor_premios > 0 && (
          <div className={`badge text-bg-primary text-light my-2`}>
            PREMIADO
          </div>
        )}

        {aposta_detalhada.status != 'AGUARDANDO VALIDACAO' && (
          <>
            {aposta_detalhada?.jogos?.map((jogo, index) => (
              <CardJogo key={jogo._id} index={index} {...jogo} />
            ))}
          </>
        )}

        {aposta_detalhada.status != 'AGUARDANDO VALIDACAO' &&
          aposta_detalhada?.jogos_futebol?.map(partida => (
            <Components.Jogo className="card p-2 mb-2" key={partida._id}>
              <Components.Row tamanho={'auto'}>
                <div className="text-center fs-6">{partida.liga.nome}</div>
              </Components.Row>
              <Components.Row>
                <div className="fw-semibold">{partida.casa}</div>
                <div className="fw-semibold text-end">{partida.fora}</div>
              </Components.Row>
              {partida.apurado && (
                <Components.Row>
                  <div className="">Placar</div>
                  <div className="fw-semibold text-end">
                    {partida.placar_casa ?? 0} x {partida.placar_fora ?? 0}
                  </div>
                </Components.Row>
              )}
              <Components.Row>
                <div>{partida.cotacao.sigla.split(';')[1]}</div>
                <div className="text-end fw-semibold">
                  {partida.cotacao.sigla.split(';')[2]}
                </div>
              </Components.Row>
              <Components.Row>
                <div>Cotação</div>
                <div className="text-end fw-semibold">
                  {partida.cotacao.valor}
                </div>
              </Components.Row>
              <Components.Row tamanho={'auto'}>
                <div className="text-center">
                  {dayjs(Number(partida.data_hora)).format('DD/MM/YYYY HH:mm') +
                    (partida.apurado && !partida.cancelado
                      ? ` - ${partida.acertou ? 'GANHOU' : 'PERDEU'}`
                      : partida.cancelado
                      ? 'CANCELADO'
                      : '')}
                </div>
              </Components.Row>
            </Components.Jogo>
          ))}
      </Components.Box>
    </Components.BackModal>
  );
}

export function CardJogo({ index, ...jogo }) {
  return (
    <div className="card p-2 mb-2 card-body  ">
      <Components.Row tamanho={'auto'}>
        <span className="text-center fs-5 fw-bold">
          {jogo.siglas.join(' ')}
        </span>
      </Components.Row>

    
      <Components.Row>
        <span>Prêmios</span>
        <span className="text-end fw-semibold">
          {handleText.premioLabel(jogo.premios)}
        </span>
      </Components.Row>

      <Components.Row>
        <span>Valor</span>
        <span className="text-end fw-semibold">
          {handleText.asMoney(jogo.valor)}
        </span>
      </Components.Row>

      {jogo.premiado && (
        <Components.Row>
          <span>Valor do prêmio</span>
          <span className="text-end fw-semibold">
            {handleText.asMoney(jogo.valor_premio)}
          </span>
        </Components.Row>
      )}

      <Components.Row>
        <span className="fw-semibold ">
        Números
        </span>
      </Components.Row>
      <div className="d-flex flex-wrap justify-content-end  gap-1  fs-6">
        {jogo.dados.map((j, index) => {
          const isPremiado = jogo.dados_acertados.find(
            acertado => acertado.dados == j
          );
          // bg - primary  rounded - pill  text - light
          return (
            <small
              key={index}
              className={
                isPremiado
                  ? `fw-semibold rounded-pill badge bg-primary`
                  : `fw-semibold`
              }
            >
              {isPremiado ? `${j} - ${isPremiado.premio}º Prêmio` : j}
            </small>
          );
        })}
      </div>
    </div>
  );
}
