import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';

import dayjs from 'dayjs';
import LoadingBalls from '../../components/loading/loading-balls';
import { useBetContext } from '../../context/betContext';
import { useContextGameJB } from '../../context/context-game-jb';
import { gameRequests } from '../../requests/gameRequests';
import { handleText } from '../../utils/handle-text';

import { Link } from 'react-router-dom';

export default function Sorteio() {
  const { changeBetState, resetGames } = useBetContext();
  const { changeStep } = useContextGameJB();
  const [loading, setLoading] = useState(false);

  const { values, setFieldValue } = useFormik({
    initialValues: {
      programacao: '',
      local: '',
      horarios: [],
    },
  });
  const [programacao, setProgramacao] = useState([]);

  async function getConfigs() {
    try {
      setLoading(true);
      let { programacoes, tiposJogos, indexGamesType, cotadasApp } =
        await gameRequests.getProgramação();
      changeBetState({
        gamesTypes: tiposJogos,
        indexGamesType,
        specialBets: cotadasApp,
      });
      getDates(programacoes);
      if (programacoes[0]) {
        setFieldValue('programacao', programacoes[0]);
        setFieldValue('horarios', programacoes[0]?.extracoes);
      }
    } catch (error) {
      throw error;
    }
    setLoading(false);
  }

  function getDates(programacoes) {
    let weekDays = [
      'Domingo',
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado',
    ];
    let arr = programacoes.slice(0, 3).map(({ data, extracoes }) => {
      let today = dayjs().get('d');
      let label =
        today == handleText.getDayFromBRToUS(data)
          ? 'Hoje'
          : `${weekDays[handleText.getDayFromBRToUS(data)]}`;
      return {
        data,
        extracoes,
        label,
      };
    });
    setProgramacao(arr);
  }

  useEffect(() => {
    resetGames();
    getConfigs();
  }, []);

  function handleSetProgramcao(dia) {
    setFieldValue('programacao', dia);
    setFieldValue('horarios', dia?.extracoes);
  }

  function salvaProgramacao() {
    let { data, extracoes } = getDataExtracoes();
    changeBetState({
      selectedDate: data,
      data,
      extracoes,
    });
    changeStep(2);
  }
  const getDataExtracoes = () => ({
    data: values.programacao?.data || false,
    extracoes: values.horarios.filter(p => p.selecionado) || [],
  });

  function renderLocais() {
    let extracoes = {};
    for (let ext of values.horarios) extracoes[ext.local] = 1;
    return Object.keys(extracoes).map((item, index) => {
      return (
        <div key={index} className="col">
          <Button
            style={{ lineHeight: 1 }}
            className="w-100 h-100"
            onClick={() => {
              setFieldValue('local', item);
            }}
            variant={values.local == item ? 'secondary' : 'primary'}
          >
            <small className="fw-bold" style={{ fontSize: '0.7rem' }}>
              {item.split('/')[1]}
            </small>
            <br />

            <small className="fw-bold text-capitalize " style={{ fontSize: '0.7rem' }}>
              {String(item.split('/')[0]).toLocaleLowerCase() }
            </small>

          </Button>
        </div>
      );
    });
  }

  function renderHorarios() {
    let horarios = [];
    let items = values.horarios.filter(item => item.local == values.local);
    horarios = items;
    return (
      <div className="row m-0 p-0">
        {horarios?.map((horario, index) => (
          <div key={index} className="col-3 p-1">
            <Button
              style={{ lineHeight: 1 }}
              className="text-uppercase w-100 h-100"
              onClick={() => {
                horario.selecionado = !horario?.selecionado;
                setFieldValue('horarios', values.horarios);
              }}
              variant={horario?.selecionado ? 'secondary' : 'primary'}
            >
              <small className="fw-bold" style={{ fontSize: '0.7rem' }}>
                {horario.descricao.split(' ')[0]}
                <br />
                {horario.descricao.split(' ')[1]}
              </small>
            </Button>
          </div>
        ))}
      </div>
    );
  }

  if (loading) {
    return <LoadingBalls />;
  }

  return (
    <div className="container px-0 d-grid  gap-1">
      <div className='d-md-grid d-none'>
      <div className="btn-group btn-group-lg    py-1 px-1 ">
        <Link
          style={{ fontSize: '0.8rem' }}
          to="/jb/resultado"
          className="btn btn-primary border py-2  "
        >
          Resultados
        </Link>
           <Link
          style={{ fontSize: '0.8rem' }}
          to="#"
          className="btn btn-primary border py-2"
        >
          Livro dos sonhos
        </Link>
        <Link
          style={{ fontSize: '0.8rem' }}
          to="#"
          className="btn btn-primary border  py-2"
        >
          Aprenda
        </Link>
        <Link
          style={{ fontSize: '0.8rem' }}
          to="/jb/regulamento"
          className="btn btn-primary  border py-2"
        >
          Regulamento
        </Link>
      </div>
     
      </div>
   

      <div className=" d-auto d-md-none  btn-group   py-1 px-1 fw-bold ">
        <Link
          style={{ fontSize: '0.8rem' }}
          to="/jb/resultado"
          className="btn btn-primary border "
        >
          Resultados
        </Link>
        <Link
          style={{ fontSize: '0.7rem' }}
          to="#"
          className="btn btn-primary border "
        >
          Livro dos sonhos
        </Link>
        <Link
          style={{ fontSize: '0.8rem' }}
          to="#"
          className="btn btn-primary border "
        >
          Aprenda
        </Link>
        <Link
          style={{ fontSize: '0.8rem' }}
          to="/jb/regulamento"
          className="btn btn-primary border "
        >
          Regulamento
        </Link>
      </div>

      <div className="d-flex flex-row gap-2 mx-2">
        {programacao.map((dia, index) => (
          <Button
            size="sm"
            key={index}
            className={`bg-gradient text-uppercase text-white 
              ${loading ? 'disabled' : ''} 
              ${
                dia.data == values.programacao.data
                  ? 'bg-primary-sm'
                  : 'bg-dark'
              } `}
            onClick={() => handleSetProgramcao(dia)}
          >
            {dia.label}
          </Button>
        ))}
      </div>

      <div className="col col-md-8">
        <div className="card text-bg-light text-dark ">
          {!!values?.programacao && (
            <>
              <div className="card-header fw-bold text-bg-dark  bg-gradient">
                <small>Estado</small>
              </div>
              <div className="card-body d-grid m-1 p-0  ">
                <div className="d-flex gap-2">{renderLocais()}</div>
              </div>
            </>
          )}
          {!!values?.local && (
            <>
              <div className="card-header fw-bold  text-bg-dark  bg-gradient">
                <small>Extrações</small>
              </div>
              <div className="card-body py-0 mx-0 px-0">{renderHorarios()}</div>
            </>
          )}
          <div className="card-footer text-center">
            <div className="d-grid gap-2 col-6 mx-auto">
              <Button
                variant="primary"
                disabled={
                  !getDataExtracoes().data ||
                  !getDataExtracoes().extracoes.length
                }
                onClick={salvaProgramacao}
                className="   fw-bold"
              >
                Continuar
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
