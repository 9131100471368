import { useLazyQuery, useQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserQueries } from '../../api/query/user';
import LoadingBalls from '../../components/loading/loading-balls';
import { ContextUsuario } from '../../context/context-user';
import usePagination from '../../hooks/usePagination';
import handleRouter from '../../utils/handle-router';
import { handleText } from '../../utils/handle-text';
import { CardProduct } from './components/cardProductEsportes';
import { CardProductJB } from './components/cardProductJB';

import { ModalDetalhado } from './components/modal-detalhado';

export default function Conta() {
  const { usuario } = useContext(ContextUsuario);
  const [saldo, setSaldo] = useState(0);
  const [apostas, setApostas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [aposta_selecionada, setApostaSelecionada] = useState(false);
  const { pagination } = usePagination();
  const [saldoQuery] = useLazyQuery(UserQueries.getClienteInfos);
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  let id = params.get('id');

  useQuery(UserQueries.getApostasUsuarioSite, {
    variables: pagination,
    fetchPolicy: 'no-cache',
    onError(error) {
      if (process.env.NODE_ENV == 'development') console.log(error);
    },
    onCompleted: data => {
      setApostas(_ => data?.getApostasUsuarioSite?.lista || []);
      setLoading(false);
    },
  });

  useQuery(UserQueries.getApostaDetalhadaUsuarioSite, {
    variables: { _id: id },
    onCompleted(data) {
      setApostaSelecionada(data?.getApostaDetalhadaUsuarioSite);
    },
    onError(err) {},
  });

  useEffect(() => {
    if (usuario) getSaldo();
  }, [usuario]);

  useEffect(() => {}, []);

  async function getSaldo() {
    try {
      let _ = await saldoQuery({ fetchPolicy: 'network-only' });
      setSaldo(_?.data?.getClienteInfos?.saldo || 0);
    } catch (error) {
      console.log('Error', error);
    }
  }

  if (usuario) {
    return (
      <div className="container py-3">
        <div className="card p-3">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="d-flex flex-column">
                <div className="fs-5 fw-bold">Dados pessoais</div>
                <span>{usuario.nome}</span>
                <span>{usuario.email}</span>
                <span>{handleText.cpf(usuario.cpf)}</span>
                <Link to={{ pathname: handleRouter.alterar_senha }}>
                  Alterar senha
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="d-flex flex-column">
                <div className="fs-5 fw-bold">Saldo</div>
                <span className="mb-1">
                  {handleText.asMoney(saldo)}
                </span>
                <Link to={{ pathname: handleRouter.saques + '?inst=new' }}>
                  Sacar
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="card p-3 mt-2 d-flex flex-column ">
          <span className="fs-5 fw-bold">Bilhetes</span>
          {loading && <LoadingBalls />}
          <div className="row">
            {!loading ? (
                apostas.map((item, index) => (
                <div key={index} className="col-md-6  col-12">
                  {item.futebol ? (
                    <CardProduct
                      {...item}
                      onClick={() => {
                        setApostaSelecionada(item);
                      }}
                    />
                  ) : (
                    <CardProductJB
                      {...item}
                      onClick={() => {
                        setApostaSelecionada(item);
                      }}
                    />
                  )}
                </div>
              ))
            ) : (
              <>
                {!loading && (
                  <div className="card p-2 text-center text-muted">
                    <h3>Sem apostas</h3>
                    <small>
                      Assim que começar a jogar, suas apostas aparecerão aqui.
                    </small>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {aposta_selecionada && (
          <ModalDetalhado
            onClose={() => {
              setApostaSelecionada(false);
            }}
            {...aposta_selecionada}
          />
        )}
      </div>
    );
  }

  return null;
}
