import { useMutation } from "@apollo/client";
import { useFormik } from 'formik';
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import * as Yup from 'yup';
import { FutMutations } from "../../api/mutation/fut";
import { Button } from "../buttons/button";

export function ForgetPassword() {
  const [loading, setLoading] = useState(false);
  const [recuperarSenhaMutation] = useMutation(FutMutations.recuperarSenha);


  const formik = useFormik({
    initialValues: {
      cpf: ""
    },
    validationSchema: Yup.object().shape({
      cpf: Yup.string().required("Campo obrigatório")
    }),
    onSubmit: submit
  })

  async function submit(values) {
    if (loading) return;
    setLoading(true);
    try {
      let { data } = await recuperarSenhaMutation({ variables: { cpf: values.cpf } });
      if (!!data?.futebolsite_recuperarSenha) alert(data.futebolsite_recuperarSenha);
      formik.resetForm();
    } catch (error) {
      alert(error);
    }
    setLoading(false);
  }

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="d-flex flex-column">
        <div>
          <label htmlFor="cpf-input">CPF ou E-mail</label>
          <input
            id="cpf-input"
            type="text"
            className="form-control"
            placeholder="CPF ou E-mail"
            onChange={formik.handleChange('cpf')}
            value={formik.values.cpf}
          />
          <small style={{ fontSize: "0.65rem" }}>* CPF sem traços e pontos</small>
        </div>
        <Button className="mt-3" type="submit" loading={loading} disabled={loading}>
          Enviar
        </Button>
      </div>
    </Form>
  );
}
