import React, { useEffect, useState } from "react";
import { gameRequests, generalRequests } from "../../requests/gameRequests";
import { useFormik } from "formik";
import { FaCircle, FaSearch, FaSquareFull, FaTrophy } from "react-icons/fa";
import { apiRequest } from "../../api";
import LoadingBalls from "../../components/loading/loading-balls";
import defaultShield from '../../assets/default.png';
import styled from "styled-components";

export default function ResultFut() {
    const [resultado, setResultado] = useState(null);
    const [modalResultado, setModalResultado] = useState(false);

    const [pesquisado, setPesquisado] = useState(false);
    const [loading, setLoading] = useState(false);
    const [resultados, setResultados] = useState({
        lista: [],
        total: 0
    })

    const formik = useFormik({
        initialValues: {
            busca: ""
        },
        onSubmit: busca
    })

    async function busca(values) {
        if (loading) return;
        setLoading(true);
        try {
            let response = await generalRequests.getResultados(values.busca);
            setResultados(response);
            setPesquisado(true);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    useEffect(() => {
        busca({busca:''})
    },[])

    useEffect(() => {
        if (resultado) {
            setModalResultado(true);
        } else {
            setModalResultado(false);
        }
    }, [resultado]);
    return (
        <div>
            <div className="px-2 pt-3 d-flex flex-column lh-1 gap-1">
                <span className="fs-3 fw-semibold">Resultados</span>
                <small className="text-primary">O resultado só estará disponível ao término da partida.</small>
                <hr className="my-0" />
            </div>
            <form onSubmit={formik.handleSubmit} className="px-2 mt-2">
                <div className="row g-4 align-items-center">
                    <div className="col-12 col-sm-5">
                        <div className="d-flex flex-row gap-3">
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Busque pelo nome do time"
                                minLength={5}
                                onChange={formik.handleChange('busca')}
                            />
                            <button type="submit" className="btn btn-sm btn-primary px-3">
                                <FaSearch />
                            </button>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className="d-flex flex-row align-items-center gap-2">
                            <FaCircle className="text-primary" /> Resultado disponível
                        </div>
                        <div className="d-flex flex-row align-items-center gap-2">
                            <FaCircle className="text-danger" /> Resultado indisponível
                        </div>
                    </div>
                </div>
            </form>
            {
                loading && (
                    <LoadingBalls />
                )
            }
            <div className="px-2">
                {
                    !loading && resultados?.lista?.length == 0 && (
                        <EmptyList pesquisado={pesquisado} is_empty={resultados.lista?.length == 0 ? true : false} />
                    )
                }
            </div>
            {
                !loading && resultados?.lista?.length > 0 && (
                    <div className="px-2">
                        <div className="row g-3 mt-2">
                            {
                                resultados?.lista?.map((item) => {
                                    let resultadoCasa = "0";
                                    let resultadoFora = "0";
                                    let [data, hora] = item.resultado.data_br.split(" ");
                                    let [dia, mes, ano] = data.split("/");
                                    let className = "col-md-6";
                                    if (item?.resultado_disponivel) {
                                        let [_casa, _fora] = item.resultado.placar.split("-");
                                        resultadoCasa = _casa;
                                        resultadoFora = _fora;
                                        className += ' cursor-pointer hover-time'
                                    }
                                    return (
                                        <div
                                            key={'item-resultado-' + item.resultado._id}
                                            onClick={() => {
                                                if (!item?.resultado_disponivel) return;
                                                setResultado({ ...item.resultado, resultadoCasa, resultadoFora, splitted_date: { dia, mes, ano, hora } });
                                            }}
                                            className={className}
                                        >
                                            <div className="card border-0 shadow-sm">
                                                <span className="gap-2 bg-dark bg-gradient text-white rounded-top p-2 d-flex flex-row align-items-center justify-content-between" style={{ fontSize: "0.8rem" }}>
                                                    <div className="flex-grow-1">
                                                        <FaTrophy className="me-1" /> {item.resultado.liga.nome} {process.env.NODE_ENV == 'development' ? item.resultado.base_id : ''}
                                                    </div>
                                                    {
                                                        item?.resultado_disponivel && (
                                                            <FaCircle className="text-primary" />
                                                        )
                                                    }
                                                    {
                                                        !item?.resultado_disponivel && (
                                                            <FaCircle className="text-danger" />
                                                        )
                                                    }
                                                </span>
                                                <div className="d-flex flex-column gap-2 p-2">
                                                    <div className="d-flex flex-row gap-3 align-items-center">

                                                        <div className="d-flex flex-column">
                                                            <span style={{ fontSize: "0.75rem" }} className="text-center">{ano}</span>
                                                            <span style={{ fontSize: "0.85rem" }} className="text-center">{dia}/{mes}</span>
                                                            <span style={{ fontSize: "0.75rem" }} className="text-center">{hora}</span>
                                                        </div>

                                                        <div className="d-flex flex-column flex-grow-1">

                                                            <div className="d-flex flex-row gap-2 justify-content-between">
                                                                <div className="d-flex flex-row gap-2">
                                                                    <img
                                                                        src={item.resultado?.casa_img || defaultShield}
                                                                        style={{ width: 20, height: 20 }}
                                                                    />
                                                                    <span>{item.resultado.casa}</span>
                                                                </div>
                                                                <span className="fw-bold">{resultadoCasa}</span>
                                                            </div>

                                                            <div className="d-flex flex-row gap-2 justify-content-between">
                                                                <div className="d-flex flex-row gap-2">
                                                                    <img
                                                                        src={item.resultado?.fora_img || defaultShield}
                                                                        style={{ width: 20, height: 20 }}
                                                                    />
                                                                    <span>{item.resultado.fora}</span>
                                                                </div>
                                                                <span className="fw-bold">{resultadoFora}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                )
            }
            {
                modalResultado && (
                    <ModalResultado
                        resultado={resultado}
                        opened={modalResultado}
                        onClose={() => {
                            setResultado(null);
                        }}
                    />
                )
            }
        </div>
    );
}

const EmptyList = ({ pesquisado, is_empty }) => {
    if (!pesquisado) {
        return (
            <div className="d-flex flex-column mt-3 gap-1">
                <small className="fw-semibold">Pesquise pelo nome do time, vamos mostrar uma lista com as últimas partidas mediante disponibilidade da base.</small>
                <hr />
                <small className="text-danger fw-bold text-center">*** Não nos responsabilizamos por resultados incorretos, essa é uma funcionalidade de terceiros. ***</small>
            </div>
        )
    }
    else if (pesquisado && is_empty) {
        return (
            <div className="d-flex flex-column mt-3 gap-1">
                <small className="text-danger fw-bold text-center">Nada encontrado</small>
            </div>
        )
    } else {
        return null;
    }
}

const Components = {
    BackModal: styled.div`
      background-color: ${({ theme }) => theme.palette_common.shadow};
      padding-top: 1.6rem;
      backdrop-filter: blur(0.2rem);
      position: fixed;
      inset: 0px;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: start;
    `,
    Box: styled.div`
        overflow-y: auto;
        max-width: 100%;
        max-height: 95%;
    `,
}

const ModalResultado = ({ resultado, opened, onClose }) => {

    const [showing, setShowing] = useState("stats");


    function renderStats() {
        if (Object.keys(resultado?.stats)?.length == 0) return null;
        if (showing != 'stats') return null;
        return (
            <div className="d-flex flex-column gap-2 px-2 mt-2">
                {
                    Object.keys(resultado.stats).map((item, index) => {
                        let [casa, fora] = resultado.stats[item];
                        return (
                            <div key={'item-stat-' + index} className="bg-dark text-white rounded p-2 text-center d-flex flex-row justify-content-between" style={{ fontSize: "0.8rem" }}>
                                <span>{casa}</span>
                                <span>{item}</span>
                                <span>{fora}</span>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    function renderMinutoMinuto() {
        if (resultado?.minuto_minuto?.length == 0) return null;
        if (showing != 'minutes') return null;
        let first_half = resultado.minuto_minuto?.filter(item => item.tempo == 1);
        let second_half = resultado.minuto_minuto?.filter(item => item.tempo == 2);
        return (
            <div className="d-flex flex-column px-2 mt-2">
                <div className="bg-dark bg-gradient p-2 rounded text-white text-center my-2">
                    1º Tempo
                </div>
                {
                    first_half?.map((item, index) => {
                        let className = 'px-3';
                        if (item?.time == 'x') {
                            className += ' text-center'
                        }
                        if (item?.time == 2) {
                            className += ' text-end'
                        }
                        return (
                            <div key={'item-timefh-' + index} className={className} style={{ fontSize: "0.8rem" }}>
                                {item.text}
                            </div>
                        )
                    })
                }
                <div className="bg-dark bg-gradient p-2 rounded text-white text-center my-2">
                    2º Tempo
                </div>
                {
                    second_half?.map((item, index) => {
                        let className = 'px-3';
                        if (item?.time == 'x') {
                            className += ' text-center'
                        }
                        if (item?.time == 2) {
                            className += ' text-end'
                        }
                        return (
                            <div key={'item-timesh-' + index} className={className} style={{ fontSize: "0.8rem" }}>
                                {item.text}
                            </div>
                        )
                    })
                }
            </div>
        )
    }


    return (
        <Components.BackModal
            onClick={onClose}
        >
            <Components.Box
                onClick={ev => {
                    ev.preventDefault();
                    ev.stopPropagation();
                }}
            >
                {
                    resultado && (
                        <>
                            <div className="card tamanho-card-resultado border-2 shadow-sm bg-white lh-0 pb-3">
                                <div className="d-flex flex-row gap-5 py-3 px-2 justify-content-center">
                                    <div className="d-flex flex-column align-items-center gap-1">
                                        <img
                                            src={resultado?.casa_img || defaultShield}
                                            style={{ width: 48, height: 48 }}
                                        />
                                        <span>{resultado.casa}</span>
                                    </div>
                                    <div className="d-flex flex-row align-items-center fs-3">
                                        {resultado.resultadoCasa} - {resultado.resultadoFora}
                                    </div>
                                    <div className="d-flex flex-column align-items-center gap-1">
                                        <img
                                            src={resultado?.fora_img || defaultShield}
                                            style={{ width: 48, height: 48 }}
                                        />

                                        <span>{resultado.fora}</span>
                                    </div>

                                </div>
                                <div className="px-2 d-flex flex-column text-center justify-content-center">
                                    {
                                        !!resultado?.liga?.nome && (
                                            <>
                                                <div className="d-flex flex-row gap-1 align-items-center justify-content-center" style={{ fontSize: "0.8rem" }}>
                                                    <FaTrophy />
                                                    <span>{resultado?.liga?.nome}</span>
                                                </div>
                                            </>
                                        )
                                    }
                                    {
                                        !!resultado?.data_br && (
                                            <>
                                                <span style={{ fontSize: "0.8rem" }}>{resultado?.data_br}</span>
                                            </>
                                        )
                                    }
                                    {
                                        !!resultado?.estadio?.nome && (
                                            <>
                                                <span style={{ fontSize: "0.8rem" }}>{resultado?.estadio?.nome}</span>
                                            </>
                                        )
                                    }
                                </div>
                                <div className="d-flex flex-row px-2 gap-2">
                                    <button onClick={() => setShowing("stats")} type="button" className={`btn ${showing == 'stats' ? 'btn-primary' : 'btn-outline-primary'}  btn-sm text-success`}>
                                        Estatísticas
                                    </button>
                                    <button onClick={() => setShowing("minutes")} type="button" className={`btn ${showing == 'minutes' ? 'btn-primary' : 'btn-outline-primary'} btn-sm text-success`}>
                                        Minuto a minuto
                                    </button>
                                </div>
                                {renderStats()}
                                {renderMinutoMinuto()}
                            </div>
                        </>
                    )
                }

            </Components.Box>
        </Components.BackModal>
    )
}