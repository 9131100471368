import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { useBetContext } from '../../context/betContext';
import { useContextGameJB } from '../../context/context-game-jb';
import { TipoJogoInput } from './components/TipoJogoInput';

export default function Finaliza() {
  const { changeStep } = useContextGameJB();
  const {
    betStates,
    prepareGame,
    handleAwardButton,
    changeValueToPay,
    saveGame,
    changeAwardValue,
    deleteGameField,
    limparPremios,
  } = useBetContext();

  useEffect(() => {
    prepareGame();
  }, []);

  function returnAwardsButtons() {
    return (
      <>
        {betStates?.tipojogo?.botoes_premio.map((value, i) => {
          let label = value.split('-');
          return (
            <TJButtonPremio
              key={i}
              onClick={() => handleAwardButton(label)}
              label={`${label[0]}º ao ${label[1]}º`}
            />
          );
        })}
      </>
    );
  }

  function saveGameWithoutClearFields() {
    saveGame();
    prepareGame(true);
  }

  const handleSaveGame = () => {
    saveGame();
    prepareGame();
    changeStep(2);
  };

  return (
    <div className="container px-0 d-grid py-1 gap-1">
      <div className="col col-md-8">
        <div className="card text-bg-light text-dark ">
          <small className="card-header fw-bold text-bg-dark  bg-gradient">
            {betStates.siglas}
          </small>

          <div className="card-body  py-0">
            <div className="row justify-content-center"></div>
          </div>

          <div className="card-body   mx-0 px-0 py-0 ">
            <div className="row  mx-0 px-0  justify-content-center">

              <div className="col-12 py-2">
                <TipoJogoInput />
              </div>
              {(betStates?.games_list || []).map((gameList, i) => {
                return (
                  <TJButtonJogo
                    onClick={() => deleteGameField(i)}
                    key={i.toString()}
                    label={gameList}
                    isGrupo={betStates.tipojogo.isGrupo}
                    animal={animais[Number(gameList)]}
                  />
                );
              })}

            </div>

            <div className='d-flex gap-1  flex-wrap justify-content-center'>
            
            </div>
          </div>
          {!(betStates?.tipojogo?.premio_fixo || []).length && (
            <>
              <small className="card-header fw-bold text-bg-dark  bg-gradient">
                Prêmios
              </small>
              <div className="card-body  mx-0 px-0 py-0  ">
                <div className="row mx-0 px-0  justify-content-center ">
                  {Object.keys(betStates?.campos_premio || {}).map(
                    (item, index) => {
                      let selecionado = betStates.campos_premio[item];
                      return (
                        <TJButtonPremio
                          selecionado={selecionado}
                          key={index.toString()}
                          onClick={() =>
                            changeAwardValue(
                              item,
                              betStates.campos_premio[item]
                            )
                          }
                          label={`${item}º`}
                        />
                      );
                    }
                  )}
                  {(betStates?.tipojogo?.botoes_premio || []).length > 0 &&
                    returnAwardsButtons()}
                </div>
              </div>
            </>
          )}
          <div className="row m-0 py-1  align-items-end ">
            <div className="col">
              {betStates?.tipojogo?.form_unico !== '1' && (
                <TJInput
                  placeholder={'Valor divide pelos números'}
                  value={betStates?.valor2 || ''}
                  disabled={!betStates.valor_fixo ? false : true}
                  type="text"
                  onChange={event => changeValueToPay(event.target.value)}
                />
              )}
            </div>


              <div className="col-4">
                <Button
                  className="  p-2 form-control rounded-2"
                  variant="danger"
                  onClick={() => limparPremios()}
                >
                  Limpar
                </Button>
              </div>

          </div>

          <div className="card-footer">
            <div className="d-grid  ">
              <div className="row gap-2  justify-content-center">
                <Button
                  className="col fw-bold"
                  variant="secondary"
                  onClick={() => changeStep(2)}
                >
                  Voltar
                </Button>

                <Button
                  disabled={!betStates.valido}
                  variant="light"
                  className="col border text-primary fw-bold"
                  onClick={saveGameWithoutClearFields}
                >
                  Salvar
                </Button>

                <Button
                  disabled={!betStates.valido}
                  className="col fw-bold"
                  variant="primary"
                  onClick={handleSaveGame}
                >
                  Continuar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function TJInput(props) {
  return (
    <input
      {...props}
      inputMode="numeric"
      style={{ fontSize: 16 }}
      className=" p-2 form-control rounded-2 custom-placeholder-size"
    ></input>
  );
}

export function TJButtonPremio(item) {
  return (
    <button
      style={{ aspectRatio: 1 }}
      onClick={item.onClick}
      className={`justify-content-center align-items-center btn col-2 m-1 shadow p-0 d-flex flex-column  text-center ${
        item.selecionado ? 'btn-secondary' : 'btn-light'
      }`}
    >
      <small style={{ fontSize: '0.7rem' }} className="fw-bold ">
        {item?.label}
      </small>
      {item.isGrupo && item.animal && (
        <div style={{ fontSize: '0.5rem' }} className="fw-bold ">
          {item?.animal}
        </div>
      )}
    </button>
  );
}

export function TJButtonJogo(item) {
  let label = item.label.split('-');

  if (label.length > 1 && item.isGrupo) {
    return (
      <button
        onClick={item.onClick}
        className={`col-5 m-1 gap-1 justify-content-center align-items-center btn   shadow  d-flex flex-row  px-2 py-3  text-center ${
          item.selecionado ? 'btn-secondary' : 'btn-light'
        }`}
      >
        {label.map(l => {
          let animal = animais[Number(l)];
          return (
            <div className="d-flex flex-column ">
              <small style={{ fontSize: '0.7rem' }} className="fw-bold ">
                {l}
              </small>
              {item.isGrupo && animal && (
                <div style={{ fontSize: '0.5rem' }} className="fw-bold ">
                  {animal}
                </div>
              )}
            </div>
          );
        })}
      </button>
    );
  }
  return (
    <button
      style={{ aspectRatio: 1 }}
      onClick={item.onClick}
      className={`justify-content-center align-items-center btn col-2 m-1 shadow p-0 d-flex flex-column  text-center ${
        item.selecionado ? 'btn-secondary' : 'btn-light'
      }`}
    >
      <small style={{ fontSize: '0.7rem' }} className="fw-bold ">
        {item?.label}
      </small>
      {item.isGrupo && item.animal && (
        <div style={{ fontSize: '0.5rem' }} className="fw-bold ">
          {item?.animal}
        </div>
      )}
    </button>
  );
}

const animais = {
  1: 'AVESTRUZ',
  2: 'ÁGUIA',
  3: 'BURRO',
  4: 'BORBOLETA',
  5: 'CACHORRO',
  6: 'CABRA',
  7: 'CARNEIRO',
  8: 'CAMELO',
  9: 'COBRA',
  10: 'COELHO',
  11: 'CAVALO',
  12: 'ELEFANTE',
  13: 'GALO',
  14: 'GATO',
  15: 'JACARÉ',
  16: 'LEÃO',
  17: 'MACACO',
  18: 'PORCO',
  19: 'PAVÃO',
  20: 'PERU',
  21: 'TOURO',
  22: 'TIGRE',
  23: 'URSO',
  24: 'VEADO',
  25: 'VACA',
};
