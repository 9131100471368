import React, { useContext, useState } from 'react';
import { VscChromeClose } from 'react-icons/vsc';
import styled, { css, keyframes } from 'styled-components';
import { ContextUsuario } from '../../../../context/context-user';
import { CreateAccount } from '../../../session/create-account';
import { ForgetPassword } from '../../../session/forget-password';
import { Login } from '../../../session/login';

const open_ = keyframes`
    to {
        opacity: 1;
    }
`;

const close_ = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
    
`;

const opacity_animation = {
  open: css`
    animation: ${open_} 1s forwards;
    /* comment fixes highlight below */
  `,
  close: css`
    animation: ${close_} 0.5s forwards;
    /* comment fixes highlight below */
  `,
  none: css`
    animation: none;
    opacity: 1;
    /* comment fixes highlight below */
  `,
};

const width = {
  wide: '40vw',
  mobile: '90vw',
};

const kinds_modal = {
  login: 'Login',
  forget_password: 'Esqueci minha senha',
  create_account: 'Criar conta',
};

const Components = {
  Back: styled.div`
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    z-index: 10;
    background: ${({ theme }) => theme.palette.primary.light}30;
    backdrop-filter: blur(0.1em);
    opacity: 0;
    ${({ animation }) => opacity_animation[animation]};
  `,
  Box: styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    align-self: center;
    z-index: 1;
    transform: translateX(-50%) translateY(-50%);
    background: ${({ theme }) => theme.palette.primary.main}D9;
    color: ${({ theme }) => theme.palette.primary.text};
    width: ${width.wide};
    max-height: 80vh;
    padding-bottom: ${({ theme }) => theme.space_size.md};
    @media (max-width: 800px) {
      width: ${width.mobile};
    }
  `,
  Header: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${({ theme }) => theme.space_size.md};
  `,
  Body: styled.div`
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0px ${({ theme }) => theme.space_size.md};
  `,
  Link: styled.div`
    cursor: pointer;
    color: ${({ theme }) => theme.palette.primary.text};
    text-decoration: underline;
    text-align: center;
    margin-top: 1rem;
  `,
  Close: styled(VscChromeClose)`
    color: ${({ theme }) => theme.palette.primary.text};
    font-size: ${({ theme }) => theme.font_size.h1};
    cursor: pointer;
  `,
};

export default function Session() {
  const { make_login, setMakeLogin } = useContext(ContextUsuario);
  const [kind_modal, setKindModal] = useState(kinds_modal.login);
  const [animation, setAnimation] = useState('open');

  if (!make_login) return false;

  function closeModal() {
    setAnimation('close');
  }

  return (
    <Components.Back
      animation={animation}
      onAnimationEnd={() => {
        if (make_login && animation === 'open') setAnimation('none');
        else {
          setMakeLogin(false);
          setAnimation('open');
        }
      }}
    >
      <Components.Box className="rounded">
        <Components.Header>
          <h3>{kind_modal}</h3>
          <Components.Close onClick={closeModal} />
        </Components.Header>
        <Components.Body>
          {kind_modal === kinds_modal.login && <Login {...{ closeModal }} />}
          {kind_modal === kinds_modal.create_account && (
            <CreateAccount {...{ closeModal }} />
          )}
          {kind_modal === kinds_modal.forget_password && (
            <ForgetPassword {...{ closeModal }} />
          )}
        </Components.Body>
        {kind_modal === kinds_modal.login ? (
          <>
            <Components.Link
              onClick={() => setKindModal(kinds_modal.forget_password)}
            >
              Esqueci minha conta
            </Components.Link>
            <Components.Link
              onClick={() => setKindModal(kinds_modal.create_account)}
            >
              Criar conta
            </Components.Link>
          </>
        ) : (
          <Components.Link onClick={() => setKindModal(kinds_modal.login)}>
            Voltar
          </Components.Link>
        )}
      </Components.Box>
    </Components.Back>
  );
}
