export default function resolverRewardFut( status, premio_futebol, valor_premios) {
  if (status == 'VALIDO') {
    if (premio_futebol == 'EM ABERTO') {
      return (
        <small className="text-uppercase badge bg-secondary">Em aberto</small>
      )
    }
    else if (premio_futebol == 'GANHOU' || valor_premios > 0) {
      return (
        <small className=" text-uppercase badge bg-primary">GANHOU</small>
      )
    }
    else if (premio_futebol == 'PERDEU') {
      return (
        <small className="text-uppercase badge bg-danger">PERDEU</small>
      )
    }
  }
  return null;
}
