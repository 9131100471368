export const handleColor = {
    shadeColor(color, percent) {
      var colorInt = parseInt(color.substring(1), 16);
  
      var R = (colorInt & 0xff0000) >> 16;
      var G = (colorInt & 0x00ff00) >> 8;
      var B = (colorInt & 0x0000ff) >> 0;
  
      R = R + Math.floor((percent / 255) * R);
      G = G + Math.floor((percent / 255) * G);
      B = B + Math.floor((percent / 255) * B);
  
      var newColorInt = (R << 16) + (G << 8) + B;
      var newColorStr = "#" + newColorInt.toString(16);
  
      return newColorStr;
    },
    invertColor(hex, bw = true) {
      if (hex.indexOf("#") === 0) {
        hex = hex.slice(1);
      }
      // convert 3-digit hex to 6-digits.
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      if (hex.length !== 6) {
        throw new Error("Invalid HEX color.");
      }
      let r = parseInt(hex.slice(0, 2), 16);
      let g = parseInt(hex.slice(2, 4), 16);
      let b = parseInt(hex.slice(4, 6), 16);
  
      if (bw) {
        // https://stackoverflow.com/a/3943023/112731
        return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#3e3e3e" : "#FFFFFF";
      }
      // invert color components
      r = (255 - r).toString(16);
      g = (255 - g).toString(16);
      b = (255 - b).toString(16);
      // pad each with zeros and return
      return (
        "#" +
        handleColor.padZero(r) +
        handleColor.padZero(g) +
        handleColor.padZero(b)
      );
    },
    padZero(str, len) {
      len = len || 2;
      var zeros = new Array(len).join("0");
      return (zeros + str).slice(-len);
    },
  };
  