import { apiRequest } from "../api"

export const gameRequests = {
    getProgramação() {
        return apiRequest({
            method: 'GET',
            url: '/v3/site/jb/programacao'
        })
    },
    novoSaque(payload) {
        return apiRequest({
            method: "POST",
            url: '/v3/site/sacar',
            data: payload
        })
    },
    aposta(data) {
        return apiRequest({
            method: 'POST',
            url: '/v3/site/jb/apostar',
            data
        })
    },
    getBilhetesJB(codigos) {
        return apiRequest({
            method: "GET",
            url: `/v3/site/jb/bilhetes?codigos=${codigos}`
        })
    },
    getRegulamentoJB() {
        return apiRequest({
            method: "GET",
            url: "/v3/site/jb/regulamento"
        })
    },
    getResultadoJB(data= ''){
        return apiRequest({
            method:'GET',
            url:`/v3/site/jb/resultado/${data}`
        })
    }
}
export const userRequests = {
    alterarSenha(nova_senha, cnova_senha) {
        return apiRequest({
            method: "POST",
            url: "/v3/site/alterar-senha",
            data: { nova_senha, cnova_senha }
        })
    }
}
export const generalRequests = {
    getResultados(busca){
        return apiRequest({
            method: "GET",
            url: `/v3/site/resultado-futebol?busca=${busca}`
        })
    }
}