import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { FutQuerys } from '../../api/query/fut';
import { Container } from 'react-bootstrap';

export default function Regulamento() {
  const [regulamento, setRegulamento] = useState('');
  const { data } = useQuery(FutQuerys.getRegulamento);

  useEffect(() => {
    if (data?.regulamento) {
      setRegulamento(decodeURI(data.regulamento));
    }
  }, [data]);

  return (
    <Container className="py-3">
      <h1>Regulamento</h1>
      <div style={{whiteSpace:'break-spaces ', fontSize:'.75rem'}}>{regulamento}</div>
    </Container>
  );
}
