import { useCallback, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useStackContext } from '../../../../context/context-stack';
import { handleText } from '../../../../utils/handle-text';
import CardFinalizaAposta from '../../../cards/card-finaliza-aposta';
import { LabelValor } from '../../finish/components/label-valor';
import { useTicketContext } from './ticket';
export default function TicketMobile() {
  const [showTicket, setShowTicket] = useState(false);
  const {
    header,
    loading,
    cart,
    form,
    setForm,
    apostar,
    usuario,
    setValorAposta,
    valorAposta,
    forma_pagamento
  } = useTicketContext();
  const { setSidebarState, setHeightTicket } = useStackContext();
  const [qtd_jogos, cotacao, ...header_] = header;
  const refBox = useCallback(element => {
    if (element) {
      setHeightTicket(element.clientHeight);
    }
  }, []);

  function getRawValueAposta() {
    let valor_apostado = 0;
    if (valorAposta.value) valor_apostado = valorAposta.value;
    return Number(valor_apostado) || 0;
  }

  function getPossivelRetorno() {
    let possivel_retorno = 0;
    if (getRawValueAposta()) possivel_retorno = getRawValueAposta();
    for (let jogo of cart) {
      if (jogo?.cotacao_selecionada?.valor) {
        possivel_retorno *= 1 * jogo.cotacao_selecionada.valor;
      }
    }
    return possivel_retorno;
  }

  console.log(cart);
  
  if (!showTicket) {
    return (
      <div className="bottom-0 bg-primary-main  z-3 w-100 position-fixed  d-md-none d-grid p-1 gap-2 text-light align-items-center ">
        <div className="row  justify-content-center  ">
          <div className="col-4 d-flex flex-column text-center   ">
            <small style={{ fontSize: '.8rem' }}>Possível retorno</small>
            <small className="fw-bold ">
              {handleText.asMoney(getPossivelRetorno())}
            </small>
          </div>

          <div className="col-4    d-flex align-items-center ">
            <input
              inputMode="numeric"
              style={{ fontSize: 16 }}
              value={valorAposta.maskedValue}
              className="btn-light  col form-control form-control-sm w-100 h-100 rounded-2"
              placeholder={'Valor'}
              onChange={({ target }) =>
                setValorAposta(handleText.maskNumber(target.value))
              }
            />
          </div>

          <div className="col-4  d-flex align-items-center ">
            <Button
              style={{ fontSize: '.8rem' }}
              className=" btn btn-primary w-100 h-100  rounded-2"
              disabled={cart.length == 0}
              onClick={async () => setShowTicket(true)}
            >
              {`Cupom `}
              {!!cart.length  && (
                <span className="badge text-bg-secondary">
                  {cart.length}
                </span>
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <Modal
        show={showTicket}
        fullscreen={true}
        onHide={() => setShowTicket(false)}
      >
        <Modal.Header
          className="bg-primary-main text-light py-2"
          closeButton
          closeVariant="white"
        >
          <Modal.Title>Cupom</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-2 py-1">
          <div className=" d-flex flex-column gap-1 ">
            {cart?.map(partida => (
              <CardFinalizaAposta {...{ partida }} key={partida?._id} />
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer className=" d-block  bg-primary-main text-light py-0    ">
          <b className="text-start text-light">Formas de pagamento</b>
          <Form>
            {forma_pagamento.map(item => (
              <Form.Check
                checked={item.value === form.forma_pagamento}
                onChange={ev =>
                  setForm(_ => ({
                    ..._,
                    forma_pagamento: item.value,
                  }))
                }
                key={item.id}
                inline
                label={item.label}
                name={'group1'}
                type={'radio'}
                id={`inline-${item.id}`}
              />
            ))}
          </Form>
          <div className="">
            {[qtd_jogos, cotacao, ...header_].map(item => (
              <LabelValor {...item} key={item.label} />
            ))}
            {form.forma_pagamento === 'SALDO' && (
              <LabelValor
                label={'Saldo'}
                valor={handleText.formatValueToMoney(usuario?.saldo)}
              />
            )}
          </div>
          <Form.Group>
            <input
              style={{ fontSize: 16 }}
              value={valorAposta.maskedValue}
              className="form-control form-control-sm"
              placeholder={'Valor'}
              onChange={({ target }) =>
                setValorAposta(handleText.maskNumber(target.value))
              }
            />
          </Form.Group>
          <div className="d-grid  ">
            <Button
              className={'fw-bold  '}
              onClick={async () => {
                await apostar();
                setSidebarState('close');
                setShowTicket(false);
              }}
              disabled={loading}
            >
              {(form['forma_pagamento'] === 'PIX' ||
                form['forma_pagamento'] === 'SALDO') &&
                !usuario &&
                'FAZER LOGIN'}
              {(form['forma_pagamento'] === 'PIX' ||
                form['forma_pagamento'] === 'SALDO') &&
                usuario &&
                'FINALIZAR'}
              {(!form['forma_pagamento'] ||
                form['forma_pagamento'] === 'COLABORADOR') &&
                'FINALIZAR'}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
