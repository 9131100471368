import React, { useContext } from 'react';
import { ContextCart } from '../../context/context-cart';
import { useNavigate } from 'react-router-dom';
import defaultShield from '../../assets/default.png';

export default function TableCotas({ data }) {
  const { addToCart, verificaCotacaoEscolhida } = useContext(ContextCart);
  const navigate = useNavigate();

  const partida = data;
  let formatado = {};
  for (let cot of partida.cotacoes) {
    let labels = cot.sigla.split(';');
    if (!formatado[labels[0]])
      formatado[labels[0]] = { sigla: labels[0], lista: {} };
    if (!formatado[labels[0]].lista[labels[1]])
      formatado[labels[0]].lista[labels[1]] = { sigla: labels[1], botoes: {} };
    if (!formatado[labels[0]].lista[labels[1]].botoes[labels[2]])
      formatado[labels[0]].lista[labels[1]].botoes[labels[2]] = {
        ...cot,
        sigla_base: labels[2],
        sigla: labels.join(';'),
        valor: cot.valor,
      };
  }

  return (
    <div
      style={{ fontSize: '0.8rem' }}
      className="d-grid gap-0  container px-0 "
    >
      <div className="row text-center  mt-1 ">
        <div className="col-12 col-md-5 d-flex  justify-content-center align-items-center">
          <img
            src={partida?.casa_img || defaultShield}
            style={{ width: 35, height: 35 }}
          />
          <div  className=" fs-3 fw-bold ">{partida?.casa || 'FORA'}</div>
        </div>
        <h3 className="col m-0 ">X</h3>
        <div className="col-12 col-md-5 d-flex  justify-content-center align-items-center">
          <img
            src={partida?.fora_img || defaultShield}
            style={{ width: 35, height: 35 }}
          />
          <div className=" fs-3 fw-bold ">{partida?.fora || 'FORA'}</div>
        </div>
        <small className="fw-bold    col-12 text-center ">
          Data: {partida?.data_hora_br || '00:00'}
        </small>
      </div>
      <div>
        {Object.entries(formatado).map(([key, value]) => (
          <div key={key}>
            {Object.entries(value.lista).map(([key, value]) => (
              <div key={key} className="d-flex flex-column gap-0 mx-1">
                <div className="bg-dark bg-gradient text-white fw-bold p-0 rounded text-center">
                  {key}
                </div>
                {Object.entries(value.botoes).map(([key, value]) => (
                  <div
                    className={`d-flex flex-row justify-content-between align-items-center p-1 rounded bg-light pointer border  ${
                      verificaCotacaoEscolhida(value) ? 'border-secondary' : ''
                    }`}
                    key={key}
                    onClick={ev => {
                      addToCart(ev, {
                        ...partida,
                        cotacao_selecionada: value,
                      });
                    }}
                  >
                    <span>{value.sigla_base}</span>
                    <div
                      className={`fw-bold 
                        col-2 
                        px-1
                        py-2
                        rounded
                        text-center
                        text-light
                        ${
                          verificaCotacaoEscolhida(value)
                            ? 'bg-secondary'
                            : 'bg-primary'
                        }
                        `}
                    >
                      {value.valor.toFixed(2)}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
