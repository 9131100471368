import React, { useContext, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo3 from '../../../../assets/logo3.png';
import { ContextUsuario } from '../../../../context/context-user';
import { handleIcons } from '../../../../utils/handle-icon';
import handleRouter from '../../../../utils/handle-router';

export default function Sidebar() {
  const navigate = useNavigate();
  const { usuario, limparSessao, limparReference, setMakeLogin, _reference } =
    useContext(ContextUsuario);
  const ref = useRef(null);
  const location = useLocation();
  return (
    <aside
      className="col-md-3 bg-primary-main d-none d-md-flex p-3 gap-3 flex-column"
      style={{ width: 300 }}
      ref={ref}
    >
      <div className="d-flex justify-content-center ">
        <img src={logo3} className=" col-6 rounded bg-primary-main  " alt="" />
      </div>
      <div className="d-flex flex-column gap-2">
        {getMenuOptions(location).map(({ name, Icon, link }) => {
          if (name != 'Início') {
            return (
              <Link
                className="text-light d-flex flex-row gap-2  btn btn-primary align-items-center"
                key={name}
                to={link}
              >
                <Icon />
                {name}
              </Link>
            );
          } else {
            return (
              <div
              className="text-light d-flex flex-row gap-2  btn btn-primary align-items-center"
              key={name}
              onClick={() => {
                navigate(link)
                navigate(0)
              }}
            >
              <Icon />
              {name}
            </div>
            )
          

          }
        })}
      </div>
      <hr />
      {usuario ? (
        <>
          <div className="d-flex flex-column gap-2">
            <Button
              variant="primary"
              onClick={() => {
                navigate(handleRouter.conta);
              }}
            >
              Minha conta
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                limparSessao();
              }}
            >
              Sair
            </Button>
          </div>
        </>
      ) : (
        <div className="d-flex flex-column">
          <Button
            variant="primary"
            onClick={() => {
              setMakeLogin(true);
            }}
          >
            Login
          </Button>
        </div>
      )}

      {_reference?._id && (
        <div className="d-flex flex-column mt-2">
          <Button
            variant="info"
            onClick={() => {
              if (window.confirm('Deseja remover a referência de indicação?')) {
                limparReference();
              }
            }}
          >
            <small>Referência</small>
            <br /> {_reference?.cod ? `${_reference?.cod}. ` : ''}
            {_reference.nome}
          </Button>
        </div>
      )}
    </aside>
  );
}

export function getMenuOptions(location) {
  let jb = location.pathname.includes('jb');
  let futebol = location.pathname.includes('esportes');

  let options = [
    {
      name: 'Conferir bilhete',
      Icon: handleIcons.Ticket,
      priority: 300,
      link: handleRouter.conferir_bilhete,
    },
    {
      name: 'Sobre ',
      Icon: handleIcons.About,
      priority: 500,
      link: handleRouter.sobre,
    },
  ];

  if (!jb && !futebol) {
    options.push(
      {
        name: 'Jogo do Bicho',
        Icon: handleIcons.Dog,
        priority: 410,
        link: handleRouter.inicio_jb,
      },
      {
        name: 'Esporte',
        Icon: handleIcons.Ball,
        priority: 400,
        link: handleRouter.inicio_esportes,
      }
    );
  } else if (jb) {
    options = [
      ...options,
      {
        name: 'Início',
        Icon: handleIcons.Home,
        priority: 100,
        link: jb ? handleRouter.inicio_jb : handleRouter.inicio_esportes,
      },
      {
        name: 'Esporte',
        Icon: handleIcons.Ball,
        priority: 400,
        link: handleRouter.inicio_esportes,
      },
      {
        name: 'Resultados',
        Icon: handleIcons.Dog,
        priority: 400,
        link: handleRouter.jb_resultado,
      },
    ];
  } else if (futebol) {
    options = [
      ...options,
      {
        name: 'Início',
        Icon: handleIcons.Home,
        priority: 100,
        link: jb ? handleRouter.inicio_jb : handleRouter.inicio_esportes,
      },
      {
        name: 'Resultados',
        Icon: handleIcons.Ball,
        priority: 350,
        link: handleRouter.esportes_resultados,
      },

      {
        name: 'Jogo do Bicho',
        Icon: handleIcons.Dog,
        priority: 400,
        link: handleRouter.inicio_jb,
      },
    ];
  }

  return options.sort((a, b) => (a.priority > b.priority ? 1 : -1));
}
