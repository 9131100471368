import React, { createContext, useContext, useState } from "react";

export const ContextStack = createContext({});
export const useStackContext = () => useContext(ContextStack);

export default function ContextStackProvider({ children }) {
  const [sidebar_state, setSidebarState] = useState("none");
  const [scroll_position, setScrollPosition] = useState(0);
  const [heightTicket, setHeightTicket] = useState(0);

  return (
    <ContextStack.Provider
      value={{
        sidebar_state,
        setSidebarState,
        scroll_position,
        setScrollPosition,
        heightTicket,
        setHeightTicket,
      }}
    >
      {children}
    </ContextStack.Provider>
  );
}
