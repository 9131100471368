import React from 'react';

export function LabelValor({ label, valor }) {
  return (
    <div
      style={{ fontSize: '.8rem' }}
      className="d-flex flex-row justify-content-between fw-semibold"
    >
      <small>{label}</small>
      <small>{valor}</small>
    </div>
  );
}
