import React, { createContext, useContext, useState } from 'react';
import ContextStackProvider from './context-stack';
import Sorteio from '../pages/Jb/Sorteio';
import TiposJogos from '../pages/Jb/TiposJogos';
import Finaliza from '../pages/Jb/Finaliza';

export const ContextGameJB = createContext({
  sorteio: null,
  setSorteio: () => false,
  currentStep: null,
  setCurrentStep: () => false,
  changeStep: index => false,
});

export const useContextGameJB = () => useContext(ContextGameJB);

export default function ContextGameJBProvider({ children }) {
  const steps = {
    1: {
      Component: Sorteio,
      id: 1,
    },
    2: {
      Component: TiposJogos,
      id: 2,
    },
    3: {
      Component: Finaliza,
      id: 3,
    },
  };

  const [currentStep, setCurrentStep] = useState(steps[1]);
  const [sorteio, setSorteio] = useState(null);

  const changeStep = index => {
    window.scrollTo(0, 0);
    let step = steps[index];
    setCurrentStep(step);
    // if (currentStep > index) cleanCampos();
  };

  return (
    <ContextGameJB.Provider
      value={{
        sorteio,
        setSorteio,
        currentStep,
        changeStep,

      }}
    >
      <ContextStackProvider>{children}</ContextStackProvider>
    </ContextGameJB.Provider>
  );
}
