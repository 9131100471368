import React, { createContext, useContext, useEffect, useState } from 'react';
import ContextStackProvider from './context-stack';

export const ContextCart = createContext({});

export const useContextCart = () => useContext(ContextCart);

export default function ContextCartProvider({ children }) {
  const [cart, setCart] = useState([]);
  const [valorAposta, setValorAposta] = useState('');

  const addToCart = (event, item) => {
    event?.preventDefault();
    event?.stopPropagation();
    let new_cart = [];
    const index_apostado = cart.findIndex(partida => partida?._id === item._id);
    if (index_apostado >= 0 && cart[index_apostado]?._id === item._id) {
      if (
        cart[index_apostado].cotacao_selecionada.id ===
        item.cotacao_selecionada.id
      ) {
        new_cart = cart.filter(partida => partida._id !== item._id);
      } else
        new_cart = cart.map((partida, index) =>
          index === index_apostado
            ? { ...partida, cotacao_selecionada: item.cotacao_selecionada }
            : partida
        );
    } else new_cart = [item, ...cart];
    setCart(new_cart);
  };

  const removeFromCart = item => {
    let new_cart = [];
    new_cart = cart.filter(partida => partida._id !== item._id);
    setCart(new_cart);
  };

  const verificaCotacaoEscolhida = item => {
    const selecionadas = cart.map(item => item.cotacao_selecionada.id);
    return selecionadas.some(selecionada => selecionada === item.id);
  };

  return (
    <ContextCart.Provider
      value={{
        cart,
        addToCart,
        setCart,
        removeFromCart,
        verificaCotacaoEscolhida,
        valorAposta,
        setValorAposta,
      }}
    >
      <ContextStackProvider>{children}</ContextStackProvider>
    </ContextCart.Provider>
  );
}
