import { useQuery } from '@apollo/client';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { FutQuerys } from '../../api/query/fut';
import LoadingBalls from '../../components/loading/loading-balls';
import TableCotas from '../../components/table/table-cotas';

export default function MaisCotacao() {
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  let base_id = params.get('id');
  const { data, loading } = useQuery(FutQuerys.getUmEventoFutebol, {
    variables: {
      base_id,
    },
  });
  if (loading) return <LoadingBalls />;
  return <TableCotas {...{ data: data?.getEventoCotacoesSite }} />;
}
