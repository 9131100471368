import React from 'react';
import { Button } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { useBetContext } from '../../context/betContext';
import { handleText } from '../../utils/handle-text';

export function Row(props) {
  return (
    <div className="d-flex justify-content-between gap-1 ">{props.children}</div>
  );
}

const estilo = {
  text:{
    fontSize:'.75rem'
  }
}

export default function CardFinalizaApostaJB(props) {
  const { removeSavedGame } = useBetContext();
  let tipo = '';
  let premios = [];

  props.item.tipojogos.map(j => (tipo += `${j.sigla} `));
  Object.keys(props.item.premios).map(p => {
    if (props.item.premios[p]) premios.push(p);
  });

  let campos = props.item.campos.map(c => c.valor).join(', ');

  return (
    <div  style={{ fontSize: 14 }} className="bg-light shadow rounded">
      <div className="d-flex bg-primary text-light rounded-top flex-row justify-content-between align-items-center px-2">
        <div className="fw-bold">{tipo}</div>

        <Button 
        className='p-0 my-1'
        style={{width:25, height:25}}
        variant="danger" 
        onClick={() => removeSavedGame(props.index)}

        >
          <AiOutlineClose />
        </Button>
      </div>

      <div className="px-2 ">
        <Row>
          <div style={estilo.text}>Premios</div>
          <div style={estilo.text}>
            {handleText.premioLabel(premios)}
          </div>
        </Row>
        <Row>
          <div style={estilo.text}>
            Números
          </div>
          <div style={estilo.text}>{campos}</div>
        </Row>
        <Row>
          <div style={estilo.text}>Valor</div>
          <div style={estilo.text}>
            {handleText.formatValueToMoney(props.item.valor)}
          </div>
        </Row>
      </div>
    </div>
  );
}
