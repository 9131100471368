import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";

export const useTimer = (expiration = dayjs(), now = dayjs()) => {
    const [tempoRestante, setTempoRestante] = useState(1);
    const [counter, setCounter] = useState(0);
    const [stop, setStop] = useState(false);
    const [isExpired, setIsExpired] = useState(false);

    const loop = useCallback(s => {
      if (s) return 0;
      let time_in_seconds = now.diff(expiration, "s");
      if (time_in_seconds > 0) {
        setIsExpired(true);
        return 0;
      }
      let diff_pos = time_in_seconds * -1;
      setTempoRestante(Number.isNaN(diff_pos) ? 0 : diff_pos);
      return setTimeout(() => {
        setCounter((_) => _ + 1);
      }, 1000);
    }, [expiration]);

    useEffect(() => {
      let sub = loop(stop);
      return () => {
        clearTimeout(sub);
        setStop(false);
        setCounter(0);
        setTempoRestante(0);
      };
    }, [counter, loop, stop]);

    function getSecondsToTime(given_seconds) {
      let dateObj = new Date(given_seconds * 1000);
      let minutes = dateObj.getUTCMinutes();
      let seconds = dateObj.getSeconds();
      let timeString =
        (Number.isNaN(minutes) ? 0 : minutes || 0).toString().padStart(2, "0") +
        ":" +
        (Number.isNaN(seconds) ? 0 : seconds || 0).toString().padStart(2, "0");
      return timeString;
    }

    return {
      time: getSecondsToTime(tempoRestante),
      isExpired,
    };
};
