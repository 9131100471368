import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import {
  AiOutlineClose
} from 'react-icons/ai';
import { ContextCart } from '../../context/context-cart';

export default function CardFinalizaAposta({ partida }) {
  const { removeFromCart = f => f } = useContext(ContextCart);

  function deleteAposta(item) {
    removeFromCart(item);
  }

  return (
    <div style={{ fontSize: 14 }} className="bg-light shadow rounded">
      <div className="d-flex bg-primary text-light rounded-top flex-row justify-content-between align-items-center px-2">
        <div className="fw-bold">
          {partida.cotacao_selecionada.sigla.split(';').pop()}
        </div>

        <Button 
        className='p-0 my-1'
        style={{width:25, height:25}}
        variant="danger" onClick={() => deleteAposta(partida)}>
          <AiOutlineClose />
        </Button>

      </div>
      <div className="px-2 ">
        {!!partida?.liga?.nome && (
          <div style={estilo.font}>{partida?.liga?.nome}</div>
        )}
        <div style={estilo.font}>
          {partida.casa} X {partida.fora}
        </div>
        <div style={estilo.font}>
          {partida.cotacao_selecionada.sigla.split(';').slice(1, 2).join('')}
        </div>
        <div style={estilo.font}>
          Cotação: {partida?.cotacao_selecionada?.valor.toFixed(2)}
        </div>
        <div style={estilo.font}>{partida.data_hora_br}</div>

      </div>
    </div>
  );
}

const estilo = {
  font: {
    fontSize: '.75rem',
  },
};
