import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.scss';
import MainLayout from './components/layout/main/main';
import { BetProvider } from './context/betContext';
import ContextCartProvider from './context/context-cart';
import ContextGameJBProvider from './context/context-game-jb';
import ContextUsuarioProvider from './context/context-user';
import { AlterarSenha } from './pages/AlterarSenha';
import ConferirBilhete from './pages/ConferirBilhete';
import Conta from './pages/Conta';
import Home from './pages/Home';
import Jb from './pages/Jb';
import MaisCotacao from './pages/MaisCotacao';
import Regulamento from './pages/Regulamento';
import RegulamentoJB from './pages/RegulamentoJB';
import { Saques } from './pages/Saques';
import { Sobre } from './pages/Sobre';
import handleRouter from './utils/handle-router';
import CompartilharCodigoBilhete from './pages/CompartilharCodigoBilhete';
import ResultadoJB from './pages/Resultado';
import ResultFut from './pages/ResultFut';


function App() {
  return (
    <ContextCartProvider>
      <ContextUsuarioProvider>
        <ContextGameJBProvider>
          <BetProvider>
            <Routes>
              <Route path={'/'} element={<MainLayout />}>
                <Route path={handleRouter.inicio_esportes} element={<Home />} />

                <Route path={handleRouter.inicio_jb} element={<Jb />} />
                <Route
                  path={handleRouter.esportes_partida}
                  element={<MaisCotacao />}
                />
                <Route path={handleRouter.conta} element={<Conta />} />
                <Route
                  path={handleRouter.alterar_senha}
                  element={<AlterarSenha />}
                />
                <Route
                  path={handleRouter.esportes_regulamento}
                  element={<Regulamento />}
                />

                <Route
                  path={handleRouter.esportes_resultados}
                  element={<ResultFut />}
                />
                <Route
                  path={handleRouter.jb_regulamento}
                  element={<RegulamentoJB />}
                />
                <Route
                  path={handleRouter.conferir_bilhete}
                  element={<ConferirBilhete />}
                />
                <Route
                  path={handleRouter.esportes_compartilhar_codigo_bilhete}
                  element={<CompartilharCodigoBilhete />}
                />
                <Route
                  path={handleRouter.jb_regulamento}
                  element={<RegulamentoJB />}
                />
                   <Route
                  path={handleRouter.jb_resultado}
                  element={<ResultadoJB />}
                />
                <Route
                  path={handleRouter.jb_compartilhar_codigo_bilhete}
                  element={<CompartilharCodigoBilhete />}
                />
                <Route path={handleRouter.saques} element={<Saques />} />
                <Route path={handleRouter.sobre} element={<Sobre />} />
              </Route>
            </Routes>
          </BetProvider>
        </ContextGameJBProvider>
      </ContextUsuarioProvider>
    </ContextCartProvider>
  );
}

export default App;
