const handleRouter = {
  //Esportes
  inicio_esportes:'/esportes',
  esportes_regulamento: `/esportes/regulamento`,
  esportes_compartilhar_codigo_bilhete: '/esportes/compartilhar-codigo-bilhete',
  esportes_partida: '/esportes/partida',
  esportes_resultados: '/esportes/resultado',
  //JB
  inicio_jb:'/jb',
  jb_regulamento: `/jb/regulamento`,
  jb_compartilhar_codigo_bilhete: '/jb/compartilhar-codigo-bilhete',
  jb_resultado: '/jb/resultado',
  //CONTA
  conta: `/conta`,
  saques: '/conta/saques',
  pedidos_saque: 'conta/pedidos-saque',
  alterar_senha: `/conta/alterar-senha`,
  conferir_bilhete: '/conta/conferir-bilhete',
  //SITE
  sobre: '/sobre',
};

export default handleRouter;
