import { gql } from "@apollo/client";

export const UserQueries = {
  getClienteInfos: gql`
    query {
      getClienteInfos {
        saldo
        pixs {
          nome
          chave_pix
        }
        dados_pix{
          tipo_chave
          chave_pix
          nome_favorecido
          cpf_favorecido
        }
      }
    }
  `,
  getApostasUsuarioSite: gql`
    query GetApostasUsuarioSite(
      $data: String
      $busca: String
      $pagina: Int
      $porpagina: Int
      $filtros: String
    ) {
      getApostasUsuarioSite(
        data: $data
        busca: $busca
        pagina: $pagina
        porpagina: $porpagina
        filtros: $filtros
      ) {
        lista {
          _id
          cod
          cotacao_bilhete
          paga
          forma_pagamento
          status
          valor_bruto
          status_premio_futebol
          data_movimento
          possivel_premio
          futebol
          jogos_futebol{
            _id
          }
          valor_premios
            jogos {
              _id
            }
         extracao {
            descricao
            }
         
        }
      }
    }
  `,
  getApostaDetalhadaUsuarioSite: gql`
    query GetApostasUsuarioSite($_id: String) {
      getApostaDetalhadaUsuarioSite(_id: $_id) {
        _id
        cod
        data_movimento
        valor_bruto
        valor_liquido
        valor_premios
        possivel_premio
        total_jogos_futebol
        forma_pagamento
        acertos
        status
        status_premio_futebol
        cotacao_bilhete
        paga
        futebol
        jogos {
          _id
          dados
          premios
          siglas
          ids
          cotada
          valor
          premiado
          acertados
          limite
          valor_premio
          dados_acertados {
            premio
            sigla
            valor_premio
            valor
            dados
          }
        }
         extracao {
            _id
            cod
            descricao
            hora_sorteio
            status
            dia_excecao
          }
        jogos_futebol {
          placar_casa
          placar_fora
          data_hora
          cancelado
          iniciou
          acertou
          apurado
          cotacao {
            ao_vivo
            sigla
            valor
          }
          liga {
            nome
          }
          casa
          fora
          _id
        }
        pagstar_pix{
          qr_code_url
          favoured
          external_reference
          checkout
          pix_key
          expiration
          expire_at
          generated_at
        }
        cancelamento {
          datahora
        }
      }
    }
  `,
  atualizandoUsuarioSite: gql`
    query {
      atualizandoUsuarioSite {
        _id
        nome
        cpf
        telefone
        email
        status
        saldo
      }
    }
  `,
};
