import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import axios from 'axios';

const mainPath =
  process.env.NODE_ENV === 'development'
    ? 'http://192.168.100.31:4004'
    : 'https://api-2.tjsystem.net';

const httpLink = new HttpLink({ uri: mainPath });

export const client = (token = '') => {
  const headerLink = setContext(() => {
    let sessao = localStorage.getItem('SESSAO');
    if (sessao) sessao = JSON.parse(sessao);
    let headers = {};
    headers['platform'] = 'FUTEBOL_SITE';
    headers['empresa-id'] = '5f5927a90ab43ddd7da94545';
    if (sessao?.token) headers['token'] = sessao.token;
    return { headers };
  });

  return new ApolloClient({
    link: headerLink.concat(httpLink),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });
};

const api_routers = {
  posts: {
    saveAposta: '/aposta_online',
    localizacao_valida: '/localizacao_valida',
    saveApostaV2: '/aposta_online_v2',
    loginStep1: '/send_sms',
    loginStep2: '/verifica_token',
    saveApostaFutebol: '/aposta_online_fut',
    aposta: '/aposta/get_one',
    smsPulo: '/aposta/envia_sms',
    sorteios: '/sorteios',
    solicita_retirada: '/solicita_retirada',
    oculta_historico: '/oculta_historico',
    providers: '/cassino-provider/lista',
    jogoById: '/get_game_by_id',
    gerarPixRecarga: '/gera_pix_recarga',
    cassino_jogos_by_tag: '/lista_cassino_by_tag',
    cassinoTags: '/cassino-game/lista_tags',
    cassinoJogos: '/cassino-game/lista',
    cassinoProviders: '/cassino-provider/lista',
    cassinoGetProvider: '/cassino-provider/get_by_slug',
    getUsuario: '/get_usuario',
  },
  gets: {
    jb_init: '/jb/init',
    aposta: '/aposta/get_one',
    historico: (page = 0, qtd = 10) => `/get_historico/${page}/${qtd}`,
    verifica_pix: '/verifica_transacao',
    user_apostas: '/get_apostas',
    user_saldo: '/get_saldo',
    user_auth_token: '/usuario/auth/',
    get_solicitacao: '/get_solicitacao/',
    soccerResults: '/resultados',
    soccer: {
      events: '/eventos',
    },
    eventos_destaques: '/eventos_destaques',
    bicho_do_dia: '/bicho_do_dia',
  },
};

const getHeaders = async sessao => {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    platform: 'FUTEBOL_SITE',
    'empresa-id': '5f5927a90ab43ddd7da94545',
  };
  sessao = localStorage.getItem('SESSAO');

  let reference = localStorage.getItem('ref');
  if (!!reference) {
    reference = JSON.parse(reference);
    headers['ref-id'] = reference._id;
  }
  if (!!sessao) {
    sessao = JSON.parse(sessao);
    headers['authorization'] = sessao?.token || '';
  }
  return headers;
};

export const apiRequest = async ({
  url,
  method = 'GET',
  data = null,
  log = false,
}) => {
  try {
    let _headers = await getHeaders();
    let requestBody = {
      method,
      url: mainPath + url,
      headers: _headers,
    };
    if (data) requestBody['data'] = JSON.stringify(data);
    if (log) console.log(JSON.stringify(requestBody, null, 2));
    let response = await axios(requestBody);

    return response.data;
  } catch (error) {
    let err = 'Não concluído, tente novamente mais tarde.';
    if (!!error?.response?.data?.message) {
      err = error?.response?.data?.message;
    }
    if (error?.response?.status == 403 && typeof window !== 'undefined') {
      localStorage.removeItem('SESSION');
      window.location.replace('/');
    }
    console.log('@@@errrr', err);
    throw err;
  }
};

export default api_routers;
