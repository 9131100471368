import dayjs from "dayjs";

export  function dataMovimento(data_movimento, futebol) {
    let _data = '';
    if (data_movimento) {
      _data = Number(data_movimento);
      if (futebol) {
        _data = dayjs(_data).format('DD/MM/YY');
      } else {
        _data = dayjs(_data).toISOString();
        _data = _data.split('T')[0].split('-').reverse().join('/');
      }
    }
    return _data;
  }