import { css } from "styled-components";
import { handleColor } from "../utils/handle-color";

const theme = {
  primary: "#014925",
  secondary: "#9f640b",
  secondary_contrast: "#e2bf61",
  centerlize: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  palette_common: {
    black: "#222831",
    white: "#ffffff",
    opaque: "#ADADAD",
    shadow: "#F4F4F970",
  },
  gradient: {
    primary: `linear-gradient(45deg, #048b48 , #014925)`,
    primary_opaco: `linear-gradient(45deg, #048b48D9 , #014925D9)`,
    secondary: `linear-gradient(220deg, #90fcc6 ,#048b48, #014925)`,
  },
  palette: {
    primary: {
      main: "#014925",
      text: handleColor.invertColor("#014925"),
    },
    secondary: {
      main: "#e6b326",
      light: "#ffe6a3",
      contrast: "#e2bf61",
      text: handleColor.invertColor("#e6b326"),
    },
    third: {
      main: "#0c0c0c",
      text: "#ffffff",
      light: "#808080",
    },
    success: {
      main: "#03A64A",
      text: "#ffffff",
      light: "#8ED19E",
    },
    danger: {
      main: "#df4759",
      text: "#ffffff",
      light: "#FF6B00",
    },
    edit: {
      main: "#15a2d6",
      text: "#ffffff",
      light: "#83cce7",
    },
    disabled: {
      main: "#BFBFBF",
      text: "#0c0c0c",
      light: "#D9D9D9",
    },
    light: {
      main: "#ffffff",
      text: "#0c0c0c",
    },
  },
  font_family: {
    poppins: {
      regular: "Poppins-Regular",
      light: "Poppins-Light",
      medium: "Poppins-Medium",
      bold: "Poppins-Bold",
    },
    montserrat: {
      regular: "Montserrat-Regular",
      light: "Montserrat-Light",
      medium: "Montserrat-Medium",
      bold: "Montserrat-Bold",
    },
  },
  font_size: {
    big: "40px",
    h1: "28px",
    h2: "24px",
    h3: "20px",
    h4: "16px",
    p: "16px",
    small: "12px",
    xsmall: "10px",
  },
  font_size_crud: {
    big: 40,
    h1: 28,
    h2: 24,
    h3: 20,
    h4: 16,
    p: 16,
    small: 12,
    xsmall: 10,
  },
  space_size: {
    xxl: "28px",
    xl: "24px",
    md: "16px",
    sm: "8px",
    xsm: "4px",
    none: "0px",
  },
  space_size_crud: {
    xxl: 28,
    xl: 24,
    md: 16,
    sm: 8,
    xsm: 4,
    none: 0,
  },
};

export default theme;
