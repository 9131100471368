export default function resolverTexts(text) {
  if (text == 'VALIDO') {
    return <small className="text-uppercase badge bg-primary">Bilhete válido</small>;
  } else if (text == 'CANCELADO') {
    return <small className=" text-uppercase badge bg-danger">Bilhete cancelado</small>;
  } else if (text == 'AGUARDANDO PAGAMENTO') {
    return (
      <small className="text-uppercase badge bg-secondary bg-gradient text-black">
        Pagamento pendente
      </small>
    );
  } else if (text == 'AGUARDANDO VALIDACAO') {
    return (
      <small className="text-uppercase badge bg-secondary bg-gradient text-light">
        Aguardando validação
      </small>
    );
  }
  return null;
}
