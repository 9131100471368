export default {
    colors: {
        black: '#000000',
        background: '#353535',
        white_FF: '#FFFFFF',
        white_F2: '#F2F2F2',
        green_standart: '#066940',
        green: '#17A63B',
        green_out: '#149962',
        green_dark: '#048C34',
        grey_one: '#D3D3D3',
        warning: '#dc3545',
        guide_paper: '#FFF6D6',
        placeholder: '#A9A9AC',
        secondary: '#e6b326'

    },
    center: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    borderRadius: {
        low: 3,
        medium: 4,
        hight: 5
    },
}
